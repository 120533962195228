import * as React from "react";
import {
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  Pagination,
  PaginationItem,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckOutRecord from "./CheckOutComplianceRecord";
import {
  pageAtom,
  complianceFilterAtom,
  complianceInfoAtom,
} from "../../atoms/atoms";
import { useRecoilState, useRecoilCallback } from "recoil";
import { ComplianceService } from "../../services/complianceService";
import { prescriptionGen } from "../../utils/dictGen";
import LoadingBlock from "../LoadingBlock";
import { QueueContext } from "../../contexts/queueContext";
function GetEncounterString(pCardInfo) {
  var wAnswer;
  if (pCardInfo.patientEncounter != null) {
    wAnswer = "Linked Encounter: " + pCardInfo.patientEncounter;
  }
  if (
    pCardInfo.potentialEncounters != null &&
    pCardInfo.potentialEncounters.len > 0
  ) {
    if (wAnswer != null) {
      wAnswer = wAnswer + "\n" + pCardInfo.potentialEncounters;
    } else {
      wAnswer = pCardInfo.potentialEncounters;
    }
  }

  return wAnswer;
}

const ComplianceCards = ({ getComplianceSubset }) => {
  const [cardInfo, setCardInfo] = useRecoilState(complianceInfoAtom);
  const [filterOptions, setFilterOptions] =
    useRecoilState(complianceFilterAtom);
  const [pages, setPages] = useRecoilState(pageAtom);
  const filtered = filterOptions.filtered;
  const [typedPage, setTypedPage] = React.useState();
  const { getComplianceFiltered } = ComplianceService();
  const [loading, setLoading] = React.useState(false);
  const qCTX = React.useContext(QueueContext);
  const { queueMessages } = qCTX;

  React.useEffect(() => {
    updateCardInfo();
  }, [queueMessages.ComplianceMessages, cardInfo]);
  const updateCardInfo = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const latestCardInfo = await snapshot.getPromise(complianceInfoAtom);
        set(complianceInfoAtom, latestCardInfo);
        //setCardInfo(latestCardInfo);
      },
    []
  );
  const handleChange = (event, value) => {
    setPages((prev) => ({
      ...prev,
      compliancePage: value,
    }));
  };
  const handleFilterChange = (event, value) => {
    setPages((prev) => ({
      ...prev,
      complianceFilteredPage: value,
    }));
  };

  const handlePageType = (event) => {
    const value = event.target.value;

    if (!isNaN(value)) {
      setTypedPage(parseInt(value, 10));
    } else {
      alert("Numeric Values Only");
      setTypedPage(1);
    }
  };
  const handleGoToPage = () => {
    if (!typedPage) return;
    if (filtered) {
      setPages((prev) => ({
        ...prev,
        complianceFilteredPage: typedPage,
      }));

      getComplianceFiltered(
        filterOptions,
        15,
        pages.complianceFilteredPage,
        (data) => {
          const get = [data.all[0]];
          const allInfo = prescriptionGen(get);

          setCardInfo(allInfo);
          setPages((prev) => ({
            ...prev,
            complianceFilteredNumberOfPages: data.pages,
          }));

          setLoading(false);
        },
        (err) => {
          console.log(err);
          setLoading(false);
        }
      );
    } else {
      setPages((prev) => ({
        ...prev,
        compliancePage: typedPage,
      }));
      getComplianceSubset();
      
    }
  };
  if (!cardInfo || loading) return <LoadingBlock />;
  return (
    <>
      <Stack direction={"column"} spacing={1.5}>
        {cardInfo
          .filter((c) => c.isAudited === false)
          .map((c, index) => (
            <Card
              key={c.scriptNumber}
              variant="outlined"
              className="prescriptionCard"
              sx={{
                backgroundColor: c.checkedOut ? "#dcdcdc" : "#F7FBFC",
                boxShadow: 5,
              }}
            >
              <Stack direction={"row"}>
                <CheckOutRecord c={c} index={index} />

                <Divider orientation="vertical" flexItem />
                <CardContent sx={{ width: 200 }}>
                  <Typography
                    align="center"
                    color="#282c34"
                    fontFamily={"unset"}
                    variant={"body1"}
                  >
                    Date Written
                  </Typography>
                  <Divider flexItem />
                  <Typography align="center" color="#58a5d3" pb={2}>
                    {c.dateWritten}
                  </Typography>
                  <Typography
                    align="center"
                    color="#282c34"
                    fontFamily={"unset"}
                    variant={"body1"}
                  >
                    Date Dispensed
                  </Typography>
                  <Divider flexItem></Divider>
                  <Typography align="center" color="#58a5d3" pb={2}>
                    {c.dateDispensed}
                  </Typography>

                  <Typography
                    align="center"
                    color="#282c34"
                    fontFamily={"unset"}
                    variant={"body1"}
                  >
                    ESP Submisison Deadline
                  </Typography>
                  <Divider flexItem></Divider>
                  <Typography align="center" color="#58a5d3">
                    {c.espSubmitDeadline}
                  </Typography>
                </CardContent>
                <Divider orientation="vertical" flexItem />
                <CardContent sx={{ width: 200 }}>
                  <Typography
                    align="center"
                    color="#282c34"
                    fontFamily={"unset"}
                    variant={"body1"}
                  >
                    Drug Name
                  </Typography>
                  <Divider flexItem />
                  <Typography align="center" color="#58a5d3" pb={2}>
                    {c.drugName} {c.drugStrength}
                  </Typography>
                  <Typography
                    align="center"
                    color="#282c34"
                    fontFamily={"unset"}
                    variant={"body1"}
                  >
                    Potential 340B Savings
                  </Typography>
                  <Divider flexItem />
                  <Typography align="center" color="#58a5d3" pb={2}>
                    {c.potentialSavings}
                  </Typography>
                  <Typography
                    align="center"
                    color="#282c34"
                    fontFamily={"unset"}
                    variant={"body1"}
                  >
                    Nonmatch Reason
                  </Typography>
                  <Divider flexItem />
                  <Typography align="center" color="#58a5d3" pb={2}>
                    {c.nonmatchReason}
                  </Typography>
                </CardContent>
                <Divider orientation="vertical" flexItem />
                <CardContent sx={{ width: 200 }}>
                  <Typography
                    align="center"
                    color="#282c34"
                    fontFamily={"unset"}
                    variant={"body1"}
                  >
                    Primary Payor
                  </Typography>
                  <Divider flexItem />
                  <Typography align="center" color="#58a5d3" pb={2}>
                    {c.primaryPayor}
                  </Typography>
                  <Typography
                    align="center"
                    color="#282c34"
                    fontFamily={"unset"}
                    variant={"body1"}
                  >
                    Secondary Payor
                  </Typography>
                  <Divider flexItem />
                  <Typography align="center" color="#58a5d3">
                    {c.secondaryPayor}
                  </Typography>
                </CardContent>
                <Divider orientation="vertical" flexItem />
                <CardContent sx={{ width: 200 }}>
                  <Typography
                    align="center"
                    color="#282c34"
                    fontFamily={"unset"}
                    variant={"body1"}
                  >
                    Potential Encounters
                  </Typography>
                  <Divider flexItem />
                  <Typography align="center" color="#58a5d3" pb={2}>
                    {GetEncounterString(c)}
                  </Typography>
                </CardContent>
              </Stack>
            </Card>
          ))}
        <Stack direction={"row"} alignItems={"center"}>
          {filtered ? (
            <Pagination
              sx={{ p: 2 }}
              count={pages.complianceFilteredNumberOfPages}
              size={"large"}
              variant={"outlined"}
              shape={"rounded"}
              page={pages.complianceFilteredPage}
              onChange={handleFilterChange}
              siblingCount={1}
              boundaryCount={1}
              renderItem={(item) => (
                <PaginationItem
                  sx={{ backgroundColor: "whitesmoke" }}
                  components={{
                    previous: ArrowBackIcon,
                    next: ArrowForwardIcon,
                  }}
                  {...item}
                />
              )}
            />
          ) : (
            <Pagination
              sx={{ p: 2 }}
              count={pages.complianceNumberOfPages}
              size={"large"}
              variant={"outlined"}
              shape={"rounded"}
              page={pages.compliancePage}
              onChange={handleChange}
              siblingCount={1}
              boundaryCount={1}
              renderItem={(item) => (
                <PaginationItem
                  sx={{ backgroundColor: "whitesmoke" }}
                  components={{
                    previous: ArrowBackIcon,
                    next: ArrowForwardIcon,
                  }}
                  {...item}
                />
              )}
            />
          )}
          <FormControl>
            <TextField
              label="Go To Page"
              placeholder="Page"
              id="visit-id-text"
              variant="outlined"
              value={typedPage}
              sx={{
                m: 1,
                marginLeft: 5,
                width: "90%",
                height: "100%",
                lineHeight: 45,
                width: 75,
                background: "whitesmoke",
              }}
              onChange={handlePageType}
            />
          </FormControl>
          <Button
            variant="contained"
            sx={{ height: 40 }}
            onClick={handleGoToPage}
          >
            Go
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
export default ComplianceCards;
