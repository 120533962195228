import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useRecoilState } from "recoil";
import { adminAuditFilterAtom } from "../../../atoms/atoms";

export default function AdminPharmacyFilter({ data, setFilterDataset }) {
  const [pharmacy, setPharmacy] = React.useState([]);
  const [adminAuditFilters, setAdminAuditFilters] =
    useRecoilState(adminAuditFilterAtom);

  React.useEffect(() => {
    const uniquePharmacies = new Set(data.map((d) => d.pharmacy));
    setPharmacy(Array.from(uniquePharmacies));
  }, [data]);

  function onChange(newValue) {
    if (newValue !== null) {
      setAdminAuditFilters((prev) => ({
        ...prev,
        pharmacy: newValue,
        filtered: true,
      }));
    } else {
      setAdminAuditFilters((prev) => ({
        ...prev,
        pharmacy: "",
      }));
    }
  }

  return (
    <Stack sx={{ width: "33%" }} marginBottom={2}>
      <Typography variant="overline">Filter by Pharmacy</Typography>
      <Autocomplete
        sx={{ background: "white" }}
        options={pharmacy}
        getOptionLabel={(option) => option}
        id="controlled-demo"
        value={adminAuditFilters.pharmacy}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        renderInput={(params) => {
          return <TextField {...params} label={"Pharmacy"} />;
        }}
      />
    </Stack>
  );
}
