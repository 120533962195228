import React from "react";
import { CardContent, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import ReferralFormPopover from "../referral/ReferralFormPopover";
import EncounterFormPopover from "./EncounterFormPopover";
import { NonmatchedService } from "../../services/nonmatchedService";
import { UserContext } from "../../contexts/userContext";
import { useRecoilState } from "recoil";
import { cardInfoAtom } from "../../atoms/atoms";
import EncounterForm from "./EncounterForm";

export default function ExpandMoreInfoCard({ c, index }) {
  const [cardInfo, setCardInfo] = useRecoilState(cardInfoAtom);

  const markAudited = async () => {
   
    setCardInfo((prev) => {
      const newArray = prev.filter((p) => p.rx_prx_id != c.rx_prx_id);
      return newArray;
    });

  
  };

  return (
    <Stack>
      <Typography
        color="#282c34"
        variant={"overline"}
        paddingRight={5}
        visibility={"visible"}
        sx={{ fontSize: 12 }}
      ></Typography>

      <CardContent>
        <Stack direction={"row"} spacing={2}>
          {/* <ReferralFormPopover cardInfo={c} markAudited={markAudited} /> */}
          {/* <EncounterFormPopover cardInfo={c} markAudited={markAudited} /> */}
          <EncounterForm
            cardInfo={c}
            markAudited={markAudited}
            viewOnly={false}
          />
        </Stack>
      </CardContent>
    </Stack>
  );
}
