import { useRecoilState } from "recoil";
import {
  cardInfoAtom,
  complianceInfoAtom,
  referralInfoAtom,
} from "../atoms/atoms";
import { useCallback, useEffect, useRef } from "react";

export function useQueueListener() {
  const [cardInfo, setCardInfo] = useRecoilState(cardInfoAtom);
  const [complianceInfo, setComplianceInfo] =
    useRecoilState(complianceInfoAtom);
  const [referralInfo, setReferralInfo] = useRecoilState(referralInfoAtom);

  const cardInfoRef = useRef(cardInfo);
  const complianceInfoRef = useRef(complianceInfo);
  const referralInfoRef = useRef(referralInfo);

  useEffect(() => {
    cardInfoRef.current = cardInfo;
    complianceInfoRef.current = complianceInfo;
    referralInfoRef.current = referralInfo;
  }, [cardInfo, complianceInfo, referralInfo]);

  const ListenForQueueMessages = useCallback(
    async ({ msg, channel, onMessageReceived }, retryCount = 0) => {
      // console.log("CardInfo:", cardInfoRef.current.length);
      // console.log("ComplianceInfo:", complianceInfoRef.current.length);
      // console.log("ReferralInfo:", referralInfoRef.current.length);

      if (
        cardInfoRef.current.length === 0 &&
        complianceInfoRef.current.length === 0 &&
        referralInfoRef.current.length === 0
      ) {
        if (retryCount < 5) {
          // console.warn(
          //   `Data not available yet. Retrying in 1 second (attempt ${
          //     retryCount + 1
          //   })`
          // );
          setTimeout(
            () =>
              ListenForQueueMessages(
                { msg, channel, onMessageReceived },
                retryCount + 1
              ),
            1000
          );
          return;
        } else {
          // console.error("Data still not available after 5 attempts. Aborting.");
          msg.nack();
          return;
        }
      }

      try {
        let idMap;
        switch (channel) {
          case "Compliance":
            idMap = new Map(
              complianceInfoRef.current.map((c) => [c.rx_prx_id, c])
            );
            break;
          case "Nonmatch_Referral":
            idMap = new Map(
              referralInfoRef.current.map((r) => [r.rx_prx_id, r])
            );
            break;
          default:
            idMap = new Map(cardInfoRef.current.map((c) => [c.rx_prx_id, c]));
        }

        let queueItem = msg.bodyToString() ?? "";
        var queueObject = JSON.parse(queueItem);
        // console.log(idMap);
        // console.log(queueObject);

        if (idMap.has(queueObject.data)) {
          const p = queueObject.data;
          // console.log(p);

          switch (channel) {
            case "Nonmatch":
              handleNonmatch(queueObject, p);
              break;
            case "Referral":
              handleReferral(queueObject, p);
              break;
            case "Encounter":
              handleEncounter(queueObject, p);
              break;
            case "Compliance":
              handleCompliance(queueObject, p);
              break;
            case "Nonmatch_Referral":
              handleNonmatchReferral(queueObject, p);
              break;
          }

          msg.ack();
          return onMessageReceived(queueObject);
        } else {
          msg.nack();
        }
      } catch (error) {
        // console.error("Error processing message:", error);
        msg.nack();
      }
    },
    []
  );

  function handleNonmatch(queueObject, p) {
    if (queueObject.method == "CheckOut") {
      setCardInfo((prev) =>
        prev.map((item) =>
          item.rx_prx_id === p
            ? { ...item, checkedOut: true, owner: queueObject.user }
            : item
        )
      );
    } else if (queueObject.method == "CheckIn") {
      setCardInfo((prev) =>
        prev.map((item) =>
          item.rx_prx_id === p ? { ...item, checkedOut: false } : item
        )
      );
    } else if (queueObject.method === "Audit") {
      setCardInfo((prev) => prev.filter((c) => c.rx_prx_id !== p));
    }
  }

  function handleReferral(queueObject, p) {
    if (queueObject.method == "Submit") {
      setReferralInfo((prev) => prev.filter((c) => c.rx_prx_id != p));
    }
  }

  function handleEncounter(queueObject, p) {
    if (queueObject.method == "Submit") {
      setCardInfo((prev) => prev.filter((c) => c.rx_prx_id != p));
    }
  }

  function handleCompliance(queueObject, p) {
    if (queueObject.method == "CheckOut") {
      setComplianceInfo((prev) =>
        prev.map((item) =>
          item.rx_prx_id === p
            ? { ...item, checkedOut: true, owner: queueObject.user }
            : item
        )
      );
    } else if (queueObject.method == "CheckIn") {
      setComplianceInfo((prev) =>
        prev.map((item) =>
          item.rx_prx_id === p ? { ...item, checkedOut: false } : item
        )
      );
    } else if (queueObject.method == "AuditCompliance") {
      setComplianceInfo((prev) => prev.filter((c) => c.rx_prx_id != p));
    }
  }

  function handleNonmatchReferral(queueObject, p) {
    if (queueObject.method == "CheckOut") {
      setReferralInfo((prev) =>
        prev.map((item) =>
          item.rx_prx_id === p
            ? { ...item, checkedOut: true, owner: queueObject.user }
            : item
        )
      );
    } else if (queueObject.method == "CheckIn") {
      setReferralInfo((prev) =>
        prev.map((item) =>
          item.rx_prx_id === p ? { ...item, checkedOut: false } : item
        )
      );
    }
  }

  return { ListenForQueueMessages };
}
