import { baseApi } from "./baseService";
import { LogOut } from "../utils/logOut";

export function AdminService() {
  const { loggedOut } = LogOut();
  const API = {
    getAllRoles: "/admin/roles",
    getAllUsers: "/admin/users",
    submitNewUser: "/admin/newUser",
    updateUser: "/admin/updateUser",
    getUserSettings: "/admin/userSettings",
    getLoggedInUserSettings: "/admin/loggedInUserSettings",
    adminUpdateUser: "/admin/adminUpdateUser",
    getUserById: "/admin/getUserById",
    getTPAList: "/admin/TPAList",
    getTPAExport: "/admin/TPAExport",
    getComplianceExport: "/admin/complianceExport",
    getAllAuditedEncounters: "/admin/encounters",
    getAllAuditedReferrals: "/admin/referrals",
    getAllAuditedCompliance: "/admin/compliance",
    exportRecords: "/admin/export",
    adminUnsubmitRecord: "/admin/unsubmit",
    getAllCheckedOutRecords: "/admin/checkedOut",
  };

  const getloggedInUserSettings = (onSuccess, onError) => {

    baseApi().then((api) =>
      api
        .post(API.getLoggedInUserSettings)
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  }
  const getAllRoles = (onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAllRoles)
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getAllUsers = (onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAllUsers)
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          loggedOut(response);
          console.log(response);
          onError && onError(response);
        })
    );
  };

  const submitNewUser = (
    user_first_name,
    user_last_name,
    email_address,
    temp_password,
    role_id,
    manager_id,
    selected_permissions,
    onSuccess,
    onError
  ) => {
    baseApi().then((api) =>
      api
        .post(API.submitNewUser, {
          user_first_name,
          user_last_name,
          email_address,
          temp_password,
          role_id,
          manager_id,
          selected_permissions,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const updateUser = (
    user_id,
    user_first_name,
    user_last_name,
    email_address,
    password,
    onSuccess,
    onError
  ) => {
    baseApi().then((api) =>
      api
        .post(API.updateUser, {
          user_id,
          user_first_name,
          user_last_name,
          email_address,
          password,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getUserSettings = (user_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getUserSettings, {
          user_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const adminUpdateUser = (
    user_id,
    user_first_name,
    user_last_name,
    email_address,
    temp_password,
    role_id,
    manager_id,
    user_is_active,
    user_secondary_roles,
    onSuccess,
    onError
  ) => {
    baseApi().then((api) =>
      api
        .post(API.adminUpdateUser, {
          user_id,
          user_first_name,
          user_last_name,
          email_address,
          temp_password,
          role_id,
          manager_id,
          user_is_active,
          user_secondary_roles,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getUserById = (user_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getUserById, { user_id })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getTPAList = (onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getTPAList, {})
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getTPAExport = (
    tpa_id,
    fromDate,
    toDate,
    is_submitted,
    onSuccess,
    onError
  ) => {
    baseApi().then((api) =>
      api
        .post(
          API.getTPAExport,
          { tpa_id, fromDate, toDate, is_submitted },
          { responseType: "blob" }
        )
        .then((response) => {
          // Changed from destructuring to full response object
          const { data, headers } = response; // Now we can access both data and headers
          onSuccess(data, headers); // Pass headers along with data to the callback
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getComplianceExport = (
    fromDate,
    toDate,

    onSuccess,
    onError
  ) => {
    baseApi().then((api) =>
      api
        .post(
          API.getComplianceExport,
          { fromDate, toDate },
          { responseType: "blob" }
        )
        .then((response) => {
          // Changed from destructuring to full response object
          const { data, headers } = response; // Now we can access both data and headers
          onSuccess(data, headers); // Pass headers along with data to the callback
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  const getAllAuditedEncounters = (userIds, onSuccess, onError) => {
    console.log(userIds);
    baseApi().then((api) =>
      api
        .post(API.getAllAuditedEncounters, { userIds })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  const getAllAuditedReferrals = (userIds, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAllAuditedReferrals, { userIds })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  const getAllAuditedCompliance = (userIds, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAllAuditedCompliance, { userIds })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  const exportRecords = (historyInfo, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(
          API.exportRecords,
          
            historyInfo,
          
          { responseType: "blob" }
        )
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const adminUnsubmitRecord = (
    rx_prx_id,
    audit_type,
    notes,
    onSuccess,
    onError
  ) => {
    baseApi().then((api) =>
      api
        .post(API.adminUnsubmitRecord, {
          rx_prx_id,
          audit_type,
          notes,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  
  const getAllCheckedOutRecords = (onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAllCheckedOutRecords)
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  return {
    getAllRoles,
    getAllUsers,
    submitNewUser,
    updateUser,
    getUserSettings,
    adminUpdateUser,
    getUserById,
    getTPAList,
    getTPAExport,
    getComplianceExport,
    getAllAuditedCompliance,
    getAllAuditedEncounters,
    getAllAuditedReferrals,
    exportRecords,
    adminUnsubmitRecord,
    getAllCheckedOutRecords,
    getloggedInUserSettings
  };
}
