import React from "react";
import {
  Button,
  Paper,
  Typography,
  Card,
  CardContent,
  Divider,
  CardHeader,
  CircularProgress,
  Box,
} from "@mui/material";
import { Stack } from "@mui/system";
import { NonmatchedService } from "../../services/nonmatchedService";
import { ReferralService } from "../../services/referralService";
import { ComplianceService } from "../../services/complianceService";

export default function AdminCheckIn({
  checkedOutRecords,
  setCheckedOutRecords,
}) {
  const [loading, setLoading] = React.useState(false);
  const { checkInRecordAdmin } = NonmatchedService();
  const { checkInNMRRecordAdmin } = ReferralService();
  const { checkInComplianceRecordAdmin } = ComplianceService();
  const handleCheckIn = (event, key) => {
    setLoading(true);
    checkInRecordAdmin(
      key,
      (data) => {
        setCheckedOutRecords((prev) => {
          const newArray = prev.filter((c) => c.audit_record_id != key);
          return newArray;
        });
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  };

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <Paper sx={{ background: "whitesmoke" }}>
      <Card variant="outlined" sx={{ width: "100%", alignItems: "center" }}>
        <CardHeader
          sx={{ mt: 2 }}
          title={` Checked Out Records: ${
            checkedOutRecords ? checkedOutRecords.length : 0
          }`}
          titleTypographyProps={{ variant: "h6" }}
        />
        <Divider sx={{ m: 1 }} />
      </Card>

      {checkedOutRecords && (
        <Stack sx={{ display: "flex" }} alignItems={"center"}>
          {checkedOutRecords.map((c) => (
            <Stack direction={"row"} sx={{ display: "flex", width: "75%" }}>
              <Card
                sx={{ m: 1, width: "100%" }}
                key={`card-${c.audit_record_id}`}
              >
                <CardContent>
                  <Stack>
                    {c.compliance ? (
                      <Typography variant="h6">COMPLIANCE RECORD</Typography>
                    ) : c.referral ? (
                      <Typography variant="h6">
                        NONMATCH REFERRAL RECORD
                      </Typography>
                    ) : (
                      <Typography variant="h6">ENCOUNTER RECORD</Typography>
                    )}
                    <Typography variant="overline">
                      Facility ID: {c.facility_id}
                    </Typography>
                    <Typography variant="overline">
                      Pharmacy ID: {c.pharmacy_id}
                    </Typography>
                    <Typography variant="overline">
                      RX Number: {c.rx_number}
                    </Typography>
                    <Typography variant="overline">
                      Fill Number: {c.rx_fill_number}
                    </Typography>
                  </Stack>
                </CardContent>
                <CardContent>
                  Check Out Time: {new Date(c.check_out_time).toDateString()}
                </CardContent>
                <CardContent>
                  User: {c.user_last_name}, {c.user_first_name}
                </CardContent>
              </Card>
              <Button
                sx={{ m: 1 }}
                variant="contained"
                key={c.audit_record_id}
                onClick={(e) => handleCheckIn(e, c.audit_record_id)}
              >
                Check In
              </Button>
            </Stack>
          ))}
        </Stack>
      )}
    </Paper>
  );
}
