import React from "react";
import { DateRangePicker, DateRange } from "@mui/x-date-pickers-pro";
import { TextField, Box, Stack, Button, Typography } from "@mui/material";

const AdminDateRangePicker = ({ setFromDate, setToDate }) => {
  const [dateRange, setDateRange] = React.useState([null, null]);

  const handleDateChange = (date) => {
    setDateRange(date);
    let fromDate = new Date(date[0].$d);
    fromDate.setHours(0, 0, 0, 0);
    fromDate = fromDate.toISOString();
    let toDate = new Date(date[1].$d);
    toDate.setHours(23, 59, 59, 999);
    toDate = toDate.toISOString();
    setFromDate(fromDate);
    setToDate(toDate);
  };
  const handleReset = () => {
    setDateRange([null, null]);
  };
  return (
    <Stack sx={{ m: 1, display: "flex" }}>
      <Typography variant="overline">Filter by Date</Typography>
      <Stack sx={{ m: 1, display: "flex" }} direction={"row"}>
        <DateRangePicker
          startText="Start Date"
          endText="End Date"
          value={dateRange}
          disabled={false}
          onChange={(newValue) => handleDateChange(newValue)}
          renderInput={(startProps, endProps) => {
            return (
              <React.Fragment>
                <TextField
                  {...startProps}
                  sx={{ background: "whitesmoke" }}
                  variant="outlined"
                />
                <Box sx={{ mx: 1 }}> to </Box>
                <TextField
                  {...endProps}
                  sx={{ background: "whitesmoke" }}
                  variant="outlined"
                />
              </React.Fragment>
            );
          }}
        />
      </Stack>
      <Button sx={{ m: 1 }} variant="outlined" onClick={handleReset}>
        {" "}
        Reset Dates{" "}
      </Button>
    </Stack>
  );
};

export default AdminDateRangePicker;
