import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { AdminService } from "../services/adminService";
import Swal from "sweetalert2";
import { UserContext } from "../contexts/userContext";
import LoadingBlock from "../components/LoadingBlock";
import * as yup from "yup";

export default function Settings() {
  const [firstName, setFirstName] = React.useState();
  const [lastName, setLastName] = React.useState();
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const userCtx = React.useContext(UserContext);
  const { user } = userCtx;
  const { getUserSettings, updateUser, getloggedInUserSettings } = AdminService();

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });

  React.useEffect(() => {
    if (!user) return;
    setLoading(true);
    getloggedInUserSettings(      
      (data) => {
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setEmail(data.username);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, []);

  const handleSave = () => {
    if (!user) return;
    try {
      passwordSchema.validateSync(password);
    } catch (error) {
      swalWithBootstrapButtons.fire("Error.", error.message, "error");
      return;
    }
    Swal.fire({
      text: "Are you sure you want to save?",
      showCancelButton: true,
      confirmButtonColor: "#1976d2",
      confirmButtonText: "Confirm",
      cancelButtonColor: "#769FCD",
      color: "black",
    }).then((result) => {
      if (result.isConfirmed) {
        updateUser(
          user.user_id,
          firstName,
          lastName,
          email,
          password,
          (data) => {
            swalWithBootstrapButtons.fire("Saved!", `${data}`, "success");
          },
          (err) => {
            console.log(err);
            swalWithBootstrapButtons.fire(
              "Error.",
              `There was an error processing your request. ${err}`,
              "error"
            );
          }
        );
      }
    });
  };
  if (loading) return <LoadingBlock />;
  return (
    <Paper>
      <Card>
        <CardHeader title="User Settings" />
        <CardContent>
          <Typography
            component={"span"}
            sx={{ display: "flex", flexDirection: "column", m: 2 }}
          >
            <FormControl sx={{ m: 1 }}>
              <TextField
                label="First Name"
                InputLabelProps={{
                  shrink: true,
                }}
                id="firstName"
                value={firstName}
                variant="outlined"
                sx={{ m: 1 }}
                defaultValue={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }}>
              <TextField
                label="Last Name"
                InputLabelProps={{
                  shrink: true,
                }}
                id="lastName"
                value={lastName}
                variant="outlined"
                sx={{ m: 1 }}
                defaultValue={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }}>
              <TextField
                label="Email Address"
                InputLabelProps={{
                  shrink: true,
                }}
                id="email"
                value={email}
                variant="outlined"
                defaultValue={email}
                sx={{ m: 1 }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }}>
              <TextField
                label="New Password"
                InputLabelProps={{
                  shrink: true,
                }}
                id="password"
                value={password}
                variant="outlined"
                sx={{ m: 1 }}
                onChange={(e) => setPassword(e.target.value)}
                defaultValue={""}
              />
              <Typography variant="caption" alignSelf={"flex-start"}>
                {
                  "  (Must be 8 characters in length, with one lowercase, uppercase, and special character. Leave blank to not change password)"
                }
              </Typography>
            </FormControl>
          </Typography>
        </CardContent>
        <Button variant="contained" onClick={handleSave} sx={{ m: 2 }}>
          Save
        </Button>
      </Card>
    </Paper>
  );
}

const passwordSchema = yup
  .string()  
  .min(8, "Password must be at least 8 characters")
  .matches(/(?=.*[a-z])/, "Password must contain at least one lowercase letter")
  .matches(/(?=.*[A-Z])/, "Password must contain at least one uppercase letter")
  .matches(
    /(?=.*[!@#$%^&*])/,
    "Password must contain at least one special character"
  );
