import * as React from "react";
import {
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  Pagination,
  PaginationItem,
  FormControl,
  TextField,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckOutRecord from "./ReferralCheckOutRecord";
import {
  auditFilterAtom,
  cardInfoAtom,
  pageAtom,
  referralFilterAtom,
  referralInfoAtom,
} from "../../atoms/atoms";
import { useRecoilState, useRecoilCallback } from "recoil";
import { prescriptionGen } from "../../utils/dictGen";
import { ReferralService } from "../../services/referralService";
import { QueueContext } from "../../contexts/queueContext";

function GetEncounterString(pCardInfo) {
  var wAnswer;
  if (pCardInfo.patientEncounter != null) {
    wAnswer = "Linked Encounter: " + pCardInfo.patientEncounter;
  }
  if (
    pCardInfo.potentialEncounters != null &&
    pCardInfo.potentialEncounters.len > 0
  ) {
    if (wAnswer != null) {
      wAnswer = wAnswer + "\n" + pCardInfo.potentialEncounters;
    } else {
      wAnswer = pCardInfo.suspectEncounterDate;
    }
  }

  return wAnswer;
}

const PrescriberCards = ({ getSubset }) => {
  const [cardInfo, setCardInfo] = useRecoilState(referralInfoAtom);
  const [filterOptions, setFilterOptions] = useRecoilState(referralFilterAtom);
  const [pages, setPages] = useRecoilState(pageAtom);
  const [typedPage, setTypedPage] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const filtered = filterOptions.filtered;
  const { getNMRFiltered } = ReferralService();
  const qCTX = React.useContext(QueueContext);
  const { queueMessages } = qCTX;

  React.useEffect(() => {
    updateCardInfo();
  }, [queueMessages.Nonmatch_ReferralMessages, cardInfo]);
  const updateCardInfo = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const latestCardInfo = await snapshot.getPromise(referralInfoAtom);
        set(referralInfoAtom, latestCardInfo);
        //setCardInfo(latestCardInfo);
      },
    []
  );
  const handleChange = (event, value) => {
    setPages((prev) => ({
      ...prev,
      referralPage: value,
    }));
  };
  const handleFilterChange = (event, value) => {
    setPages((prev) => ({
      ...prev,
      referralFilteredPage: value,
    }));
  };
  const handlePageType = (event) => {
    const value = event.target.value;

    // Check if the value is numeric
    if (!isNaN(value)) {
      setTypedPage(parseInt(value, 10));
    } else {
      alert("Numeric Values Only");
      setTypedPage(1);
    }
  };
  const handleGoToPage = () => {
    if (!typedPage) return;
    if (filtered) {
      setPages((prev) => ({
        ...prev,
        referralFilteredPage: typedPage,
      }));
      getNMRFiltered(
        filterOptions,
        15,
        pages.referralFilteredPage,
        (data) => {
          const get = [data.all[0]];
          const allInfo = prescriptionGen(get);
          setCardInfo(allInfo);
          setPages((prev) => ({
            ...prev,
            filteredNumberOfPages: data.pages,
          }));

          setLoading(false);
        },
        (err) => {
          console.log(err);
          setLoading(false);
        }
      );
    } else {
      setPages((prev) => ({
        ...prev,
        referralPage: typedPage,
      }));

      getSubset();
    }
  };
  if (!cardInfo || loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <>
        <Stack direction={"column"} spacing={1.5}>
          {cardInfo
            .filter((c) => c.isAudited === false)
            .map((c, index) => (
              <Card
                key={c.scriptNumber}
                variant="outlined"
                className="prescriptionCard"
                sx={{
                  backgroundColor: c.checkedOut ? "#dcdcdc" : "#F7FBFC",
                  boxShadow: 5,
                }}
              >
                <Stack direction={"row"}>
                  <CheckOutRecord c={c} index={index} />

                  <Divider orientation="vertical" flexItem />
                  <CardContent sx={{ width: 200 }}>
                    <Typography
                      align="center"
                      color="#282c34"
                      fontFamily={"unset"}
                      variant={"body1"}
                    >
                      Date Written
                    </Typography>
                    <Divider flexItem />
                    <Typography align="center" color="#58a5d3" pb={2}>
                      {c.dateWritten}
                    </Typography>
                    <Typography
                      align="center"
                      color="#282c34"
                      fontFamily={"unset"}
                      variant={"body1"}
                    >
                      Date Dispensed
                    </Typography>
                    <Divider flexItem></Divider>
                    <Typography align="center" color="#58a5d3" pb={2}>
                      {c.dateDispensed}
                    </Typography>

                    <Typography
                      align="center"
                      color="#282c34"
                      fontFamily={"unset"}
                      variant={"body1"}
                    >
                      ESP Submission Deadline
                    </Typography>
                    <Divider flexItem></Divider>
                    <Typography align="center" color="#58a5d3">
                      {c.espSubmitDeadline}
                    </Typography>
                  </CardContent>
                  <Divider orientation="vertical" flexItem />
                  <CardContent sx={{ width: 200 }}>
                    <Typography
                      align="center"
                      color="#282c34"
                      fontFamily={"unset"}
                      variant={"body1"}
                    >
                      Drug Name
                    </Typography>
                    <Divider flexItem />
                    <Typography align="center" color="#58a5d3" pb={2}>
                      {c.drugName} {c.drugStrength}
                    </Typography>
                    <Typography
                      align="center"
                      color="#282c34"
                      fontFamily={"unset"}
                      variant={"body1"}
                    >
                      Potential 340B Savings
                    </Typography>
                    <Divider flexItem />
                    <Typography align="center" color="#58a5d3" pb={2}>
                      {c.potentialSavings}
                    </Typography>
                    <Typography
                      align="center"
                      color="#282c34"
                      fontFamily={"unset"}
                      variant={"body1"}
                    >
                      Nonmatch Reason
                    </Typography>
                    <Divider flexItem />
                    <Typography align="center" color="#58a5d3" pb={2}>
                      {c.nonmatchReason}
                    </Typography>
                  </CardContent>
                  <Divider orientation="vertical" flexItem />
                  <CardContent sx={{ width: 200 }}>
                    <Typography
                      align="center"
                      color="#282c34"
                      fontFamily={"unset"}
                      variant={"body1"}
                    >
                      Primary Payor
                    </Typography>
                    <Divider flexItem />
                    <Typography align="center" color="#58a5d3" pb={2}>
                      {c.primaryPayor}
                    </Typography>
                    <Typography
                      align="center"
                      color="#282c34"
                      fontFamily={"unset"}
                      variant={"body1"}
                    >
                      Secondary Payor
                    </Typography>
                    <Divider flexItem />
                    <Typography align="center" color="#58a5d3">
                      {c.secondaryPayor}
                    </Typography>
                  </CardContent>
                  <Divider orientation="vertical" flexItem />
                  <CardContent sx={{ width: 200 }}>
                    <Typography
                      align="center"
                      color="#282c34"
                      fontFamily={"unset"}
                      variant={"body1"}
                    >
                      Potential Encounters
                    </Typography>
                    <Divider flexItem />
                    <Typography align="center" color="#58a5d3" pb={2}>
                      {GetEncounterString(c)}
                    </Typography>
                  </CardContent>
                </Stack>
              </Card>
            ))}
          <Stack direction={"row"} alignItems={"center"}>
            {filtered ? (
              <Pagination
                sx={{ p: 2 }}
                count={pages.referralFilteredNumberOfPages}
                size={"large"}
                variant={"outlined"}
                shape={"rounded"}
                page={pages.referralFilteredPage}
                onChange={handleFilterChange}
                siblingCount={1}
                boundaryCount={1}
                renderItem={(item) => (
                  <PaginationItem
                    sx={{ backgroundColor: "whitesmoke" }}
                    components={{
                      previous: ArrowBackIcon,
                      next: ArrowForwardIcon,
                    }}
                    {...item}
                  />
                )}
              />
            ) : (
              <Pagination
                sx={{ p: 2 }}
                count={pages.referralNumberOfPages}
                size={"large"}
                variant={"outlined"}
                shape={"rounded"}
                page={pages.referralPage}
                onChange={handleChange}
                siblingCount={1}
                boundaryCount={1}
                renderItem={(item) => (
                  <PaginationItem
                    sx={{ backgroundColor: "whitesmoke" }}
                    components={{
                      previous: ArrowBackIcon,
                      next: ArrowForwardIcon,
                    }}
                    {...item}
                  />
                )}
              />
            )}
            <FormControl>
              <TextField
                label="Go To Page"
                placeholder="Page"
                id="visit-id-text"
                variant="outlined"
                value={typedPage}
                sx={{
                  m: 1,
                  marginLeft: 5,
                  width: "90%",
                  height: "100%",
                  lineHeight: 45,
                  width: 75,
                  background: "whitesmoke",
                }}
                onChange={handlePageType}
              />
            </FormControl>
            <Button
              variant="contained"
              sx={{ height: 40 }}
              onClick={handleGoToPage}
            >
              Go
            </Button>
          </Stack>
        </Stack>
      </>
    );
  }
};
export default PrescriberCards;
