import React from "react";
import { CardContent, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import ReferralFormPopover from "./ReferralFormPopover";
//import EncounterFormPopover from "./EncouFormPopover";
import { NonmatchedService } from "../../services/nonmatchedService";
import { UserContext } from "../../contexts/userContext";
import { useRecoilState } from "recoil";
import { cardInfoAtom, referralInfoAtom } from "../../atoms/atoms";
import ReferralForm from "./ReferralForm";

export default function ExpandMoreInfoCard({ c, index }) {
  const [cardInfo, setCardInfo] = useRecoilState(referralInfoAtom);

  const markAudited = async () => {
    // let updateInfo = [...cardInfo];
    // let d = { ...c };
    // d.isAudited = true;
    // updateInfo[index] = d;
    // setCardInfo(updateInfo);
    setCardInfo((prev) => {
      const newArray = prev.filter((p) => p.rx_prx_id != c.rx_prx_id);
      return newArray;
    });

    // await markNonmatchedAsAudited(
    //   c.pk,
    //   c.rx_prx_id,
    //   (data) => {
    //     console.log(data);
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
  };

  return (
    <Stack>
      <Typography
        color="#282c34"
        variant={"overline"}
        paddingRight={5}
        visibility={"visible"}
        sx={{ fontSize: 12 }}
      ></Typography>

      <CardContent>
        <Stack direction={"row"} spacing={2}>
          {/* <ReferralFormPopover cardInfo={c} markAudited={markAudited} /> */}
          {/* <EncounterFormPopover cardInfo={c} markAudited={markAudited} /> */}
          <ReferralForm
            cardInfo={c}
            markAudited={markAudited}
            viewOnly={false}
          />
        </Stack>
      </CardContent>
    </Stack>
  );
}
