import React from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { UserContext } from "../../contexts/userContext";
import { AdminService } from "../../services/adminService";
import Swal from "sweetalert2";

export default function AdminUpdateUser({ setAnchorEl, user_id, setEditUser }) {
  const [userInfo, setUserInfo] = React.useState();
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [tempPassword, setTempPassword] = React.useState(null);
  const [roles, setRoles] = React.useState([]);
  const [selectRoles, setSelectRoles] = React.useState("");
  const [permissions, setPermissions] = React.useState([]);
  const [selectedPermissions, setSelectedPermissions] = React.useState([]);
  const [managers, setManagers] = React.useState([]);
  const [selectManagers, setSelectManagers] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [active, setActive] = React.useState("true");
  const userCTX = React.useContext(UserContext);
  const { user } = userCTX;
  const { adminUpdateUser, getAllRoles, getAllUsers, getUserById } =
    AdminService();
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });

  React.useEffect(() => {
    if (!user || user.role_id != 3) return;
    setLoading(true);
    getAllRoles(
      (data) => {
        setRoles(data.filter((r) => r.role_id < 4));
        setPermissions(data.filter((r) => r.role_id >= 4));
      },
      (err) => {
        console.log(err);
      }
    );
    getAllUsers(
      (data) => {
        setManagers(data);
      },
      (err) => {
        console.log(err);
      }
    );
    //Need to add a get user and populate the initial values with the existing ones
    getUserById(
      user_id,
      (data) => {
        setUserInfo(data);
        setLoading(false);
        setFirstName(data.user_first_name);
        setLastName(data.user_last_name);
        setEmail(data.username);
        setSelectedPermissions(data.user_secondary_roles);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  const handleChangeRole = (e) => {
    setSelectRoles(e.target.value);
  };
  const handleChangeManager = (e) => {
    setSelectManagers(e.target.value);
  };
  const handlePermissionChange = (event, permissionId) => {
    if (event.target.checked) {
      setSelectedPermissions([...selectedPermissions, permissionId]);
    } else {
      setSelectedPermissions(
        selectedPermissions.filter((id) => id !== permissionId)
      );
    }
  };
  const handleSubmit = () => {
    adminUpdateUser(
      user_id,
      firstName,
      lastName,
      email,
      tempPassword,
      selectRoles,
      selectManagers,
      active,
      selectedPermissions,
      () => {
        setAnchorEl(null);
        swalWithBootstrapButtons.fire(
          "Submitted!",
          `User has been Updated.`,
          "success"
        );
        setEditUser(null);
      },
      (err) => {
        setAnchorEl(null);
        swalWithBootstrapButtons.fire(
          "Error.",
          `There was an error processing your request. ${err}`,
          "error"
        );
        setEditUser(null);
      }
    );
  };
  React.useEffect(() => {
    if (!userInfo || roles.length < 1 || managers.length < 1) return;
    if (userInfo.role_id != 3) {
      let currentRole = roles.filter((r) => r.role_id == userInfo.role_id);
      setSelectRoles(currentRole[0].role_id);
    }
    let currentManager =
      userInfo.manager_id &&
      managers.filter((m) => m.user_id == userInfo.manager_id);
    if (!currentManager) return;
    setSelectManagers(currentManager[0].user_id);
  }, [userInfo, roles, managers]);
  if (!user || user.role_id != 3) return;

  return (
    <Paper variant="outlined" sx={{ minWidth: 400 }}>
      {loading ? (
        <Box sx={{ width: "100%" }} p={5} justifyContent={"center"}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography
            component={"span"}
            sx={{ display: "flex", flexDirection: "column", m: 2 }}
          >
            <FormControl sx={{ m: 1 }}>
              <InputLabel htmlFor="my-input" shrink={Boolean(firstName)}>
                First Name
              </InputLabel>
              <Input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }}>
              <InputLabel htmlFor="my-input" shrink={Boolean(lastName)}>
                Last Name
              </InputLabel>
              <Input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }}>
              <InputLabel htmlFor="my-input" shrink={Boolean(email)}>
                Email Address
              </InputLabel>
              <Input value={email} onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl sx={{ m: 1 }}>
              <InputLabel htmlFor="my-input">Temporary Password</InputLabel>
              <Input
                value={tempPassword}
                onChange={(e) => setTempPassword(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectRoles}
                  label="Role"
                  onChange={handleChangeRole}
                >
                  {roles.map((role, index) => (
                    <MenuItem value={role.role_id} key={role.role_id}>
                      {`${role.name} User: (${role.description})`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Manager</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectManagers}
                label="Manager"
                onChange={handleChangeManager}
              >
                {managers.map((man, index) => {
                  return (
                    <MenuItem value={man.user_id} key={man.user_id}>
                      {`${man.user_first_name} ${man.user_last_name}`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Typography>
          <FormControl
            onChange={(e) => setActive(e.target.value)}
            fullWidth
            sx={{ alignItems: "center" }}
          >
            <Typography>Activate/Deactivate User?</Typography>
            <RadioGroup name="deactivate_user" value={active}>
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Activate"
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Deactivate"
              />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ m: 1 }}>
            <Typography variant="h6">Page Permissions</Typography>
            {permissions.filter((a)=> a.role_type == 1).map((permission) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedPermissions.includes(permission.role_id)}
                    onChange={(event) =>
                      handlePermissionChange(event, permission.role_id)
                    }
                  />
                }
                label={permission.name}
                key={permission.role_id}
              />
            ))}
          
          <Typography variant="h6">User Permissions</Typography>
          {permissions.filter((a)=> a.role_type == 2).map((permission) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedPermissions.includes(permission.role_id)}
                  onChange={(event) =>
                    handlePermissionChange(event, permission.role_id)
                  }
                />
              }
              label={permission.name}
              key={permission.role_id}
            />
          ))}
          </FormControl>

          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ width: "100%" }}
          >
            Save
          </Button>
        </>
      )}
    </Paper>
  );
}
