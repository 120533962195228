import React from "react";
import {
  CircularProgress,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import AuditHistoryDetailCard from "./AdminAuditHistoryDetailCard";
import { NonmatchedService } from "../../../services/nonmatchedService";
import { UserContext } from "../../../contexts/userContext";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpSharpIcon from "@mui/icons-material/ArrowDropUpSharp";
import { useRecoilState } from "recoil";
import { adminAuditFilterAtom } from "../../../atoms/atoms";

export default function AdminAuditHistoryHeader({
  encounters,
  referrals,
  compliances,
  loading,
  filter,
}) {
  const [historyFilter, setHistoryFilter] =
    useRecoilState(adminAuditFilterAtom);
  const userCtx = React.useContext(UserContext);
  const [ascDescSort, setAscDescSort] = React.useState(true);
  const [showSortIcon, setShowSortIcon] = React.useState(false);
  const [encounterInfo, setEncounterInfo] = React.useState([]);
  const [referralInfo, setReferralInfo] = React.useState([]);
  const [complianceInfo, setComplianceInfo] = React.useState([]);
  const [allInfo, setAllInfo] = React.useState([]);

  React.useEffect(() => {
    if (encounters) {
      setEncounterInfo(encounters);
    }
    if (referrals) {
      setReferralInfo(referrals);
    }
    if (compliances) {
      setComplianceInfo(compliances);
    }

    if (encounters && referrals && compliances) {
      //  setLoading(false);
    }
  }, [encounters, referrals, compliances]);

  React.useEffect(() => {
    if (
      encounterInfo.length >= 1 ||
      referralInfo.length >= 1 ||
      complianceInfo.length >= 1
    ) {
      setAllInfo([...encounterInfo, ...referralInfo, ...complianceInfo]);
    }
  }, [encounterInfo, referralInfo, complianceInfo]);

  React.useEffect(() => {
    if (historyFilter.filtered) {
      const filteredInfo = allInfo.filter((a) => filter.includes(a.rx_prx_id));
      setAllInfo(filteredInfo);
    } else {
      setAllInfo([...encounterInfo, ...referralInfo, ...complianceInfo]);
    }
  }, [historyFilter, filter]);

  function handleAuditDateSort() {
    setShowSortIcon(true);
    setAscDescSort(!ascDescSort);
    ascDescSort
      ? allInfo.sort((a, b) =>
          a.auditDateUpdated.localeCompare(b.auditDateUpdated)
        )
      : allInfo.sort((a, b) =>
          b.auditDateUpdated.localeCompare(a.auditDateUpdated)
        );
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="audit header">
        <TableHead>
          <TableRow className="historyTableHead">
            <TableCell component="th" scope="row" />
            <TableCell component="th" scope="row">
              RX ID
            </TableCell>
            <TableCell component="th" scope="row">
              Date Dispensed
            </TableCell>
            <TableCell component="th" scope="row">
              Amount
            </TableCell>
            <TableCell component="th" scope="row">
              Auditor
            </TableCell>
            <TableCell component="th" scope="row">
              Outcome
            </TableCell>
            <TableCell component="th" scope="row">
              Audit Type
            </TableCell>
            <TableCell component="th" scope="row">
              Status
            </TableCell>
            <TableCell
              className="sort"
              onClick={handleAuditDateSort}
              component="th"
              scope="row"
            >
              Audit Date
              {showSortIcon ? (
                <Icon>
                  {ascDescSort ? (
                    <ArrowDropDownSharpIcon />
                  ) : (
                    <ArrowDropUpSharpIcon />
                  )}
                </Icon>
              ) : (
                ""
              )}
            </TableCell>
            <TableCell component="th" scope="row">
              Audit Time (minutes)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell colSpan={10} style={{ textAlign: "center" }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
          {allInfo.length >= 1 &&
            allInfo.map((c, index) =>
              c && c.pk && c.pk.rx_number ? (
                <Row
                  key={index}
                  row={c}
                  index={index}
                  setAllInfo={setAllInfo}
                  allInfo={allInfo}
                />
              ) : null
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function Row(props) {
  const { row, index, setAllInfo, allInfo } = props;
  const [open, setOpen] = React.useState(false);
  const [checkOut, setCheckOut] = React.useState();
  const { checkInRecord, checkOutRecord } = NonmatchedService();
  const statusDict = {
    1: "New",
    2: "In Process",
    3: "Completed",
    4: "Submitted",
    5: "Cancelled",
    6: "Pending CE Review",
    7: "Pending",
    8: "Compliant",
    9: "Non-Compliant",
    10: "Aged-Out",
    11: "Ignore",
  };

  React.useEffect(() => {
    if (open) {
      checkOutRecord(
        row.rx_prx_id,
        (data) => {
          setCheckOut(true);
        },
        (err) => {
          console.log(err);
        }
      );
    }
    if (!open && checkOut) {
      checkInRecord(
        row.rx_prx_id,
        (data) => {
          setCheckOut(false);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, [open]);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <TableRow
        className={index % 2 === 0 ? "EvenRow" : ""}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={handleOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.pk.rx_number}</TableCell>
        <TableCell>
          {new Date(row.dateDispensed).toLocaleDateString()}
        </TableCell>
        <TableCell>{row.potentialSavings}</TableCell>
        <TableCell>{row.auditor}</TableCell>
        <TableCell>{row.outcome}</TableCell>
        <TableCell>{row.auditType}</TableCell>
        <TableCell>{statusDict[row.status]}</TableCell>
        <TableCell>{new Date(row.auditDateUpdated).toDateString()}</TableCell>
        <TableCell>{row.auditTimeMinutes}</TableCell>
      </TableRow>
      <TableRow className={index % 2 === 0 ? "EvenRow" : ""}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <AuditHistoryDetailCard
              setOpen={setOpen}
              row={row}
              setAllInfo={setAllInfo}
              allInfo={allInfo}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
