import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
const urls = require('../config').default.urls;

export const baseApi = async () => {
  var userApiKey = "";
  var userSessionId = "";
  var user = null;
  var prefix = "";

  try {
    // let userFromStorage = localStorage.getItem("prorx_user");
    // user = userFromStorage && JSON.parse(userFromStorage);
    let userFromCookie = Cookies.get("prorx_user");
    user = userFromCookie && JSON.parse(userFromCookie);
    userApiKey = user.api_key;
    userSessionId = user.sessionId;
    prefix = user.db_prefix;


    var baseurl = `${urls.base}/`;

  } catch (error) {
    console.log(
      "Something bad happened while trying to get the user api key from local storage."
    );
    console.log(error);
    showLoggedOutMessage();
  }

  
  const showLoggedOutMessage = () => {

    Swal.fire({
      title: "Logged Out",
      text: "You have been logged out.",
      icon: "warning",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed || result.isDismissed) {
        window.location.href = `${baseurl}/Login`;
      }
    });
  }
  
  const instance = axios.create({
    baseURL: baseurl,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  

  return instance;
};
