import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Button, CardHeader } from "@mui/material";
import { AuditService } from "../services/auditService";
import { AdminService } from "../services/adminService";
import { UserContext } from "../contexts/userContext";
import AdminPharmacyFilter from "../components/admin/adminAudit/AdminAuditPharmacyFilter";
import AuditDateRangePicker from "../components/history/AuditDateRangePicker";
import { historyGen } from "../utils/dictGen";
import { useRecoilState } from "recoil";
import { adminAuditFilterAtom } from "../atoms/atoms";
import fileDownload from "js-file-download";
import ErrorPage from "./ErrorPage";
import AdminAuditHistoryHeader from "../components/admin/adminAudit/AdminAuditHistoryHeader";
import AdminAuditorFilter from "../components/admin/adminAudit/AdminAuditorFilter";
import AdminAuditTypeFilter from "../components/admin/adminAudit/AdminAuditTypeFilter";

export default function AdminAudit() {
  const [adminAuditFilters, setAdminAuditFilters] =
    useRecoilState(adminAuditFilterAtom);
  const [encounters, setEncounters] = React.useState([]);
  const [referrals, setReferrals] = React.useState([]);
  const [compliance, setCompliance] = React.useState([]);
  const [dataset, setDataset] = React.useState([]);
  const [filterDataset, setFilterDataset] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [load1, setLoad1] = React.useState(false);
  const [load2, setLoad2] = React.useState(false);
  const [load3, setLoad3] = React.useState(false);
  const {
    getAllAuditedEncounters,
    getAllAuditedReferrals,
    exportRecords,
    getAllAuditedCompliance,
  } = AdminService();
  const userCtx = React.useContext(UserContext);
  const user = userCtx.user;

  const getData = () => {
    getAllAuditedEncounters(
      !user.hierarchy
        ? [user.user_id]
        : [...user.hierarchy, user.user_id],
      (data) => {
        if (data[0].length < 1) {
          setLoad1(true);
          return;
        } else {
          const allInfo = historyGen(data, "Encounter");
          setEncounters(allInfo);
        }
        setLoad1(true);
      },
      (err) => {
        console.log(err);
        setLoad1(true);
      }
    );

    getAllAuditedReferrals(
      !user.hierarchy
        ? [user.user_id]
        : [...user.hierarchy, user.user_id],
      (data) => {
        if (data[0].length < 1) {
          setLoad2(true);
          return;
        } else {
          const allInfo = historyGen(data, "Referral");
          setReferrals(allInfo);
        }
        setLoad2(true);
      },
      (err) => {
        console.log(err);
        setLoad2(true);
      }
    );

    getAllAuditedCompliance(
      !user.hierarchy
        ? [user.user_id]
        : [...user.hierarchy, user.user_id],
      (data) => {
        if (data[0].length < 1) {
          setLoad3(true);
          return;
        } else {
          const allInfo = historyGen(data, "Compliance");
          setCompliance(allInfo);
        }
        setLoad3(true);
      },
      (err) => {
        console.log(err);
        setLoad3(true);
      }
    );
  };

  React.useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  React.useEffect(() => {
    if (load1 && load2 && load3) setLoading(false);
  }, [load1, load2, load3]);

  React.useEffect(() => {
    setDataset([...encounters, ...referrals, ...compliance]);
  }, [encounters, referrals, , compliance]);

  React.useEffect(() => {
    let data = [...dataset];

    if (adminAuditFilters.filtered) {
      if (adminAuditFilters.auditor) {
        data = data.filter((d) => d.auditor == adminAuditFilters.auditor);
      }
      if (adminAuditFilters.pharmacy) {
        data = data.filter((d) => d.pharmacy == adminAuditFilters.pharmacy);
      }
      if (adminAuditFilters.auditType) {
        data = data.filter((d) => d.auditType == adminAuditFilters.auditType);
      }
      if (adminAuditFilters.start_date && adminAuditFilters.end_date) {
        let fromDate = new Date(adminAuditFilters.start_date);
        let toDate = new Date(adminAuditFilters.end_date);
        data = data.filter(
          (d) =>
            new Date(d.auditDateCreated) >= fromDate &&
            new Date(d.auditDateCreated) <= toDate
        );
      }
    } else {
      data = [...dataset];
    }

    setFilterDataset(() => data);
  }, [adminAuditFilters, dataset]);

  React.useEffect(() => {
    if (
      !adminAuditFilters.auditor &&
      !adminAuditFilters.pharmacy &&
      !adminAuditFilters.auditType &&
      !(adminAuditFilters.start_date && adminAuditFilters.end_date)
    ) {
      setAdminAuditFilters((prev) => ({
        ...prev,
        filtered: false,
      }));
    }
  }, [
    adminAuditFilters.auditor,
    adminAuditFilters.pharmacy,
    adminAuditFilters.auditType,
    adminAuditFilters.start_date,
    adminAuditFilters.end_date,
  ]);

  const handleExport = () => {
    setLoading(true);
    const querySet = filterDataset.map((f) => {
      let obj = {
        rx_prx_id: f.rx_prx_id,
        auditor: f.auditor,
        outcome: f.outcome,
        audit_type: f.auditType,
        status_id: f.status,
        audit_date_created: f.auditDateCreated,
        audit_date_updated: f.auditDateUpdated,
        audit_time: f.auditTimeMinutes,
      };
      return obj;
    });
    exportRecords(
      querySet,
      (data) => {
        fileDownload(
          data,
          `AdminAudit_Report_${new Date().toLocaleDateString()}.xlsx`
        );
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  };

  if (user.role_id != 3) {
    return <ErrorPage />;
  }

  return (
    <Stack>
      <Box sx={{ width: 1, height: 400 }}>
        <CardHeader
          title="Admin Audit"
          titleTypographyProps={{
            fontSize: 25,
            variant: "overline",
            textAlign: "center",
            fontStyle: "underline",
          }}
          sx={{ background: "grey" }}
        />
        <Stack
          sx={{ display: "flex", background: "#B9D7EA" }}
          alignItems={"center"}
        >
          <AdminAuditorFilter
            data={filterDataset}
            setFilterDataset={setFilterDataset}
          />
          <AdminPharmacyFilter
            data={filterDataset}
            setFilterDataset={setFilterDataset}
          />
          <AdminAuditTypeFilter
            data={filterDataset}
            setFilterDataset={setFilterDataset}
          />
          <AuditDateRangePicker
            original={dataset}
            data={filterDataset}
            setFilterDataset={setFilterDataset}
            type={"admin"}
          />
        </Stack>
        <Button
          onClick={handleExport}
          sx={{ justifySelf: "end", margin: 2 }}
          variant="contained"
        >
          Download Report
        </Button>

        <AdminAuditHistoryHeader
          encounters={encounters}
          referrals={referrals}
          compliances={compliance}
          loading={loading}
          filter={filterDataset.map((f) => f.rx_prx_id)}
        />
      </Box>
    </Stack>
  );
}
