import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useRecoilState } from "recoil";
import { historyFilterAtom } from "../../atoms/atoms";

export default function HistoryOutcomeFilter({ data, setFilterDataset }) {
  const [outcome, setOutcome] = React.useState([]);
  const [historyFilters, setHistoryFilters] = useRecoilState(historyFilterAtom);

  React.useEffect(() => {
    const uniqueOutcomes = new Set(data.map((d) => d.outcome).filter((outcome) => outcome !== null && outcome !== "" && outcome != undefined));
    setOutcome(Array.from(uniqueOutcomes));
  }, [data]);



  function onChange(newValue) {

    if (newValue !== null) {
      setHistoryFilters((prev) => ({
        ...prev,
        outcome: newValue,
        filtered: true,
      }));
    } else {
      setHistoryFilters((prev) => ({
        ...prev,
        outcome: "",
      }));
    }
  }

  return (
    <Stack sx={{ width: "33%" }} marginBottom={2}>      
      <Typography variant="overline">Filter by Outcome</Typography>
      <Autocomplete
        sx={{ background: "white" }}
        options={outcome}
        id="outcome_autocomplete"
        value={historyFilters.outcome}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        renderInput={(params) => {
          return <TextField {...params} label="Outcome" />;
        }}
      />
    </Stack>
  );
}
