import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useRecoilState } from "recoil";
import { historyFilterAtom } from "../../atoms/atoms";

export default function HistoryTypeFilter({ data, setFilterDataset }) {
  const [type, setType] = React.useState([]);
  const [historyFilters, setHistoryFilters] = useRecoilState(historyFilterAtom);

  React.useEffect(() => {
    const uniqueTypes = new Set(data.map((d) => d.auditType));
    setType(Array.from(uniqueTypes));
  }, [data]);

  function onChange(newValue) {

    if (newValue !== null) {
      // const filteredData = data.filter((d) => {
      //   return d.auditType === newValue;
      // });
      // setFilterDataset(filteredData);
      setHistoryFilters((prev) => ({
        ...prev,
        auditType: newValue,
        filtered: true,
      }));
    } else {
      setHistoryFilters((prev) => ({
        ...prev,
        auditType: "",
      }));
      //setFilterDataset(data);
    }
  }

  return (
    <Stack sx={{ width: "33%" }}>
      <Typography variant="overline">Filter by Audit Type</Typography>
      <Autocomplete
        sx={{ background: "white" }}
        options={type}
        id="controlled-demo"
        value={historyFilters.auditType}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        renderInput={(params) => {
          return <TextField {...params} label="Audit Type" />;
        }}
      />
    </Stack>
  );
}
