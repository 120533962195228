import { baseApi } from "./baseService";
import { LogOut } from "../utils/logOut";

export function ReferralService() {
  const { loggedOut } = LogOut();
  //NMR= "Nonmatched Referrals"
  const API = {
    submitReferral: "/referral/submit",
    updateReferral: "/referral/update",
    getReferral: "/referral/get",
    //new calls
    getAllNMR: "/referral/all",
    markNMRAsAudited: "/referral/audit",
    getNumberOfNMRPages: "/referral/count",
    checkOutNMRRecord: "/referral/checkOut",
    checkInNMRRecord: "/referral/checkIn",
    checkInAllNMRRecords: "/referral/checkInAll",
    getNMRFiltered: "/referral/filter",
    getAllNMRPrescribers: "/referral/prescribers",
    getAllNMRPharmacies: "/referral/pharmacies",
    checkInNMRRecordAdmin: "/referral/checkInAdmin",
  };

  const submitReferral = (
    rx_prx_id,
    referralProvider,
    referralEncounterLocation,
    NPI,
    referralDate,
    referralOneYear,
    referralVisitId,
    referralMRN,
    referralNotes,
    requiredDocs,
    docDate,
    docOneYear,
    prescribingVisitId,
    prescribingMRN,
    prescribingNotes,
    status,
    outcome,
    onSuccess,
    onError
  ) => {
    baseApi().then((api) =>
      api
        .post(API.submitReferral, {
          "rx_prx_id" : rx_prx_id,
          "referralProvider": referralProvider,
          "location" : referralEncounterLocation,
          "referralEncounterDate": referralDate,
          "meetsHospitalPolicies": referralOneYear,
          "visitId": referralVisitId,
          "mrn": referralMRN,
          "otlnotes": referralNotes,
          "documentation": requiredDocs,
          "returnDate": docDate,
          "outcome": outcome,
          "ctlnotes": prescribingNotes,
          "submit":true
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const updateReferral = (
    rx_prx_id,
    referralProvider,
    referralEncounterLocation,
    referralDate,
		  referralOneYear,
		  referralVisitId,
		  referralMRN,
		  referralNotes,
		  requiredDocs,
		  docDate,
		  outcome,
		  prescribingNotes,
      submit,
    onSuccess,
    onError
  ) => {
    
     
    
    baseApi().then((api) =>


      api
        .post(API.submitReferral, {
          "rx_prx_id" : rx_prx_id,
          "referralProvider": referralProvider,
          "location" : referralEncounterLocation,
          "referralEncounterDate": referralDate ? referralDate : "",
          "meetsHospitalPolicies": referralOneYear ? referralOneYear : "", 
          "visitId": referralVisitId ? referralVisitId : "",
          "mrn": referralMRN,
          "otlnotes": referralNotes ? referralNotes : "",
          "documentation": requiredDocs,
          "returnDate": docDate ? docDate : "",
          "outcome": outcome,
          "ctlnotes": prescribingNotes ? prescribingNotes : "",
          "submit":submit
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getReferral = (rx_prx_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getReferral, {
          rx_prx_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  //Need to add in new calls once new logic is available
  const getAllNMR = (limit, offset, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAllNMR, { limit, offset })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const markNMRAsAudited = async (
    rx_prx_id,
    qualified,
    notes,
    onSuccess,
    onError
  ) => {
    baseApi().then((api) =>
      api
        .post(API.markNMRAsAudited, { rx_prx_id, qualified, notes })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getNumberOfNMRPages = (onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getNumberOfNMRPages)
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const checkOutNMRRecord = (rx_prx_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.checkOutNMRRecord, {
          rx_prx_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const checkInNMRRecord = (rx_prx_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.checkInNMRRecord, {
          rx_prx_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const checkInNMRRecordAdmin = (compliance_record_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.checkInNMRRecordAdmin, {
          compliance_record_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          onError && onError(response);
        })
    );
  };

  const checkInAllNMRRecords = (user_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.checkInAllNMRRecords, {
          user_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getNMRFiltered = (filterOptions, limit, offset, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getNMRFiltered, { filterOptions, limit, offset })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getAllNMRPrescribers = (onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAllNMRPrescribers)
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getAllNMRPharmacies = (onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAllNMRPharmacies)
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  return {
    getReferral,
    submitReferral,
    updateReferral,
    getAllNMR,
    markNMRAsAudited,
    getNumberOfNMRPages,
    checkOutNMRRecord,
    checkInNMRRecord,
    checkInNMRRecordAdmin,
    checkInAllNMRRecords,
    getNMRFiltered,
    getAllNMRPrescribers,
    getAllNMRPharmacies,
  };
}
