import {
  Card,
  CardHeader,
  Paper,
  TextField,
  Typography,
  Button,
  Stack,
  Box,
  CardContent,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import dayjs from "dayjs";
import React from "react";
import { ReferralService } from "../../services/referralService";
import { UserContext } from "../../contexts/userContext";
import ClearIcon from "@mui/icons-material/Clear";
import Swal from "sweetalert2";
import { FacilityService } from "../../services/facilityService";
import { AuditService } from "../../services/auditService";
import LoadingBlock from "../LoadingBlock";
import { NonmatchedService } from "../../services/nonmatchedService";

export default function ReferralForm({
  cardInfo,
  markAudited,
  handleClose,
  viewOnly,
}) {
  const [referralEncounterDate, setReferralEncounterDate] = React.useState("");
  const [documentationReturnDate, setDocumentationReturnDate] =
    React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [locationSelect, setLocationSelect] = React.useState([]);
  const [locationPick, setLocationPick] = React.useState("");
  const [exists, setExists] = React.useState(false);
  const [submitButton, setSubmitButton] = React.useState(true);
  const [status, setStatus] = React.useState();
  const [OTL, setOTL] = React.useState([]);
  const [CTL, setCTL] = React.useState([]);
  const [readOnly, setReadOnly] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    referralProvider: "",
    referralEncounterLocation: locationPick,
    NPI: "",
    referralDate: referralEncounterDate,
    referralOneYear: "false",
    referralVisitId: "",
    referralMRN: "",
    referralNotes: "",
    requiredDocs: "",
    docDate: documentationReturnDate,
    prescribingNotes: "",
    outcome: "",
  });

  const { checkInRecord } = NonmatchedService();
  const { userOptOut } = AuditService();
  const { get340BFacilities } = FacilityService();
  const { getReferral, submitReferral, updateReferral } = ReferralService();
  const { user, saveFormUser, saveUser } = React.useContext(UserContext);
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });

  React.useEffect(() => {
    setLoading(true);
    setReadOnly(cardInfo.isReaudit);
    getReferral(
      cardInfo.rx_prx_id,
      (d) => {
          setExists(true);
          setFormValues({
            referralProvider: d.provider,
            referralEncounterLocation: d.facility_id,
            NPI: d.NPI,
            referralDate: d.enc_date,
            referralOneYear: d.meets_hospital_policies,
            referralVisitId: d.visit_id,
            referralMRN: d.mrn_id,
            requiredDocs: d.documentation_valid,
            docDate: d.documentation_date,
            docOneYear: d.doc_one_year,
            outcome: d.outcome,
          });
          setLocationPick(d.facility_id);
          setOTL(d.otlnotes);
          setCTL(d.ctlnotes);
          d.enc_date && setReferralEncounterDate(dayjs(d.enc_date));
          d.documentation_date && setDocumentationReturnDate(dayjs(d.documentation_date));
          
        
        setLoading(false);
      },
      (err) => {
        setExists(false);
        setLoading(false);
        console.log(err);
      }
    );
    get340BFacilities(
      (data) => {
        let facilityNames = data.map((f) => f.name);
        setLocationSelect(facilityNames);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  React.useEffect(() => {
    saveFormUser();

    if (formValues.referralProvider) {
      if (!formValues.referralEncounterLocation) {
        setFormValues((prev) => ({
          ...prev,
          referralEncounterLocation: locationPick,
        }));
      } else {
        if (formValues.referralDate) {
          if (formValues.referralVisitId) {
            if (formValues.referralMRN) {
              if (
                formValues.requiredDocs == "true" ||
                formValues.requiredDocs == true
              ) {
                if (formValues.docDate) {
                  if (
                    formValues.outcome &&
                    (formValues.outcome === "Reverse/Requalify" ||
                      formValues.outcome === "Remain Non Matched" ||
                      formValues.outcome === "Pending CE Review")
                  ) {
                    setSubmitButton(false);
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [formValues]);

  const handleChangeReferralEncounterDate = (newValue) => {
    setReferralEncounterDate(newValue);
    setFormValues((prev) => ({
      ...prev,
      referralDate: newValue,
    }));
  };
  const handleChangeDocumentationReturnDate = (newValue) => {
    setDocumentationReturnDate(newValue);
    setFormValues((prev) => ({
      ...prev,
      docDate: newValue,
    }));
  };
  const handleSelectLocation = (e) => {
    setFormValues((prev) => ({
      ...prev,
      referralEncounterLocation: e.target.value,
    }));
    setLocationPick(e.target.value);
    
  };
  React.useEffect(() => {
},[locationPick])

  const handleSelectOutcome = (e) => {
    if (e.target.value == "Pending CE Review") {
      setStatus(() => 6);
    }

    setFormValues((prev) => ({
      ...prev,
      outcome: e.target.value,
    }));
  };

  const updateForm = (submit) => {
    updateReferral(
      cardInfo.rx_prx_id,
      formValues.referralProvider,
      locationPick,
      formValues.referralDate,
      formValues.referralOneYear,
      formValues.referralVisitId,
      formValues.referralMRN,
      formValues.referralNotes,
      formValues.requiredDocs,
      formValues.docDate,
      formValues.outcome,
      formValues.prescribingNotes,
      submit,
      (data) => {
        setLoading(false);
        swalWithBootstrapButtons.fire(
          submit ? "Submitted": "Saved!",
          `Encounter form for Facility: ${cardInfo.pk.facility_id}, Pharmacy: ${cardInfo.pk.pharmacy_id}, Rx Number: ${cardInfo.pk.rx_number}, Fill Number: ${cardInfo.pk.rx_fill_number} has been saved.`,
          "success"
        );
      },
      (err) => {
        console.log(err);
        setLoading(false);
        swalWithBootstrapButtons.fire(
          "Error.",
          `There was an error processing your request. ${err}`,
          "error"
        );
      }
    );
    markAudited();
  }
  const handleSave = () => {
    // handleClose();
    if (!user) return;
    if (user.opt_out_save) {
      
        saveFormUser();
        setLoading(true);
        updateForm(false);
    } else {
      if (exists) {
        Swal.fire({
          title: "Save this record?",
          showCancelButton: true,
          confirmButtonColor: "#1976d2",
          confirmButtonText: "Confirm",
          cancelButtonColor: "#769FCD",
          color: "black",
          footer: `
    <input type="checkbox" id="agree-checkbox" style="width: 10px; height: 10px;">
    <label for="agree-checkbox" style="font-size:12px;">Don't show this message again</label>
  `,
          preConfirm: () => {
            if (document.getElementById("agree-checkbox").checked) {
              userOptOut(
                true,
                "save",
                (data) => {},
                (err) => {
                  console.log(err);
                }
              );
              const newUser = {
                ...user,
                opt_out_save: true,
              };
              saveUser(newUser);
              return true;
            } else {
              return undefined;
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            saveFormUser();
            setLoading(true);
            updateForm(false);
          }
        });
      } else {
        Swal.fire({
          title: "Save this record?",
          showCancelButton: true,
          confirmButtonColor: "#1976d2",
          confirmButtonText: "Confirm",
          cancelButtonColor: "#769FCD",
          color: "black",
          footer: `
    <input type="checkbox" id="agree-checkbox" style="width: 10px; height: 10px;">
    <label for="agree-checkbox" style="font-size:12px;">Don't show this message again</label>
  `,
          preConfirm: () => {
            if (document.getElementById("agree-checkbox").checked) {
              userOptOut(
                true,
                "save",
                (data) => {},
                (err) => {
                  console.log(err);
                }
              );
              const newUser = {
                ...user,
                opt_out_save: true,
              };
              saveUser(newUser);
              return true;
            } else {
              return undefined;
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            saveFormUser();
            setLoading(true);
            updateForm(false);
          }
        });
      }
    }
  };

  const handleEditForm = () => {
    setReadOnly(false);
  }
  const handleSubmit = () => {
    // handleClose();
    if (!user) return;
    if (user.opt_out_submit) {
        setLoading(true);
        saveFormUser();
        updateForm(true);
      
    } else {
      if (exists) {
        Swal.fire({
          title: "Submit this record?",
          text: "Once submitted, the record can no longer be edited.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1976d2",
          confirmButtonText: "Confirm",
          cancelButtonColor: "#769FCD",
          color: "black",
          footer: `
    <input type="checkbox" id="agree-checkbox" style="width: 10px; height: 10px;">
    <label for="agree-checkbox" style="font-size:12px;">Don't show this message again</label>
  `,
          preConfirm: () => {
            if (document.getElementById("agree-checkbox").checked) {
              userOptOut(
                true,
                "submit",
                (data) => {},
                (err) => {
                  console.log(err);
                }
              );
              const newUser = {
                ...user,
                opt_out_submit: true,
              };
              saveUser(newUser);
              return true;
            } else {
              return undefined;
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            setLoading(true);
            saveFormUser();
            updateForm(true);
          }
        });
      } else {
        Swal.fire({
          title: "Submit this record?",
          text: "Once submitted, the record can no longer be edited.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1976d2",
          confirmButtonText: "Confirm",
          cancelButtonColor: "#769FCD",
          color: "black",
          footer: `
    <input type="checkbox" id="agree-checkbox" style="width: 10px; height: 10px;">
    <label for="agree-checkbox" style="font-size:12px;">Don't show this message again</label>
  `,
          preConfirm: () => {
            if (document.getElementById("agree-checkbox").checked) {
              userOptOut(
                true,
                "submit",
                (data) => {},
                (err) => {
                  console.log(err);
                }
              );
              const newUser = {
                ...user,
                opt_out_submit: true,
              };
              saveUser(newUser);
              return true;
            } else {
              return undefined;
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            setLoading(true);
            saveFormUser();
            updateForm(true);
          }
        });
      }
    }
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <Paper
      className="referralForm"
      sx={{ backgroundColor: "#B9D7EA", padding: 1, width: "1200px" }}
    >
      <Typography align="right">
        {/* <Button onClick={() => handleClose()}>
          <ClearIcon />
        </Button> */}
      </Typography>
      <Stack direction={"column"} flex>
        <Card sx={{ width: "100%" }}>
          <CardHeader
            title="Referral Form"
            sx={{ textAlign: "center", backgroundColor: "whitesmoke" }}
          />
          <CardContent sx={{}}>
            <Typography
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ m: 0.5 }}>
                <u>
                  <b>RX ID:</b>
                </u>
                {` ${
                  cardInfo.scriptNumberDisplay
                    ? cardInfo.scriptNumberDisplay
                    : `${JSON.stringify(cardInfo.pk, undefined, " ")}`
                }`}
              </Typography>
              <Typography sx={{ m: 0.5 }}>
                <u>
                  <b>Prescribing Physician:</b>
                </u>
                {` ${cardInfo.prescriberName}`}
              </Typography>
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                m: 1,
              }}
            >
              <Typography sx={{ m: 0.5 }}>
                <u>
                  <b>Drug Name:</b>
                </u>
                {` ${cardInfo.drugName}`}
              </Typography>
              <Typography sx={{ m: 0.5 }}>
                <u>
                  {" "}
                  <b>Date Written:</b>
                </u>{" "}
                {`${cardInfo.dateWritten}`}
              </Typography>
            </Typography>
          </CardContent>
        </Card>
        <Stack direction={"row"} flex sx={{ width: "100%" }}>
          <Paper
            sx={{
              textAlign: "center",
              backgroundColor: "#D6E6F2",
              padding: 1,
              width: "50%",
              m: 0.5,
            }}
          >
            <CardHeader title="Opening the Loop" />
            <Typography>
              Documenting Entity prescriber referred patient to prescribing
              physician
            </Typography>

            <Card sx={{ width: "90%", m: 1 }}>
              <Typography sx={{ m: 1 }}>Referral Provider *</Typography>
              <TextField
                disabled={readOnly}
                required
                id="standard-required"
                variant="standard"
                value={formValues.referralProvider}
                sx={{ m: 1, width: "90%" }}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    referralProvider: e.target.value,
                  }))
                }
              />
            </Card>
            <Card sx={{ width: "90%", m: 1 }}>
              <Typography sx={{ m: 1 }}>
                Referral Encounter Location *
              </Typography>
              <FormControl disabled={readOnly}>
                <Select
                  labelId="locations-select"
                  id="locations-selection"
                  value={locationPick}
                  onChange={(e) => handleSelectLocation(e)}
                  autoWidth
                  label={locationPick}
                  sx={{ backgroundColor: "whitesmoke" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {locationSelect.map((loc, index) => (
                    <MenuItem key={index} value={loc}>
                      {loc}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Card>
            {/* <Card sx={{ width: "90%", m: 1 }}>
              <Typography sx={{ m: 1 }}>Referral Provider NPI</Typography>
              <TextField
                id="standard-required"
                value={formValues.NPI}
                variant="standard"
                sx={{ m: 1, width: "90%" }}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    NPI: e.target.value,
                  }))
                }
              />
            </Card> */}
            <Card sx={{ width: "90%", m: 1 }}>
              <Typography sx={{ m: 1 }}>Referral Encounter Date *</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Typography sx={{ m: 1 }}>
                  <DesktopDatePicker
                    label="Encounter Date"
                    inputFormat="MM/DD/YYYY"
                    disabled={readOnly}
                    value={referralEncounterDate}
                    onChange={handleChangeReferralEncounterDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Typography>
              </LocalizationProvider>
            </Card>
            <Card sx={{ width: "90%", m: 1 }}>
              <FormControl
              disabled={readOnly}
                sx={{ m: 2 }}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    referralOneYear: e.target.value,
                  }))
                }
              >
                <Typography>
                  Is the Referral Date Between 0 - 365 Days Prior to the Rx
                  Written Date?
                </Typography>
                <RadioGroup
                disabled={readOnly}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={formValues.referralOneYear}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Card>
            <Card sx={{ width: "90%", m: 1 }}>
              <Typography sx={{ m: 1 }}>
                Referral Visit ID (Encounter Number) *
              </Typography>
              <TextField
              disabled={readOnly}
                required
                id="standard-required"
                value={formValues.referralVisitId}
                variant="standard"
                sx={{ m: 1, width: "90%" }}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    referralVisitId: e.target.value,
                  }))
                }
              />
            </Card>
            <Card sx={{ width: "90%", m: 1 }}>
              <Typography sx={{ m: 1 }}>MRN *</Typography>
              <TextField
              disabled={readOnly}
                required
                id="standard-required"
                value={formValues.referralMRN}
                variant="standard"
                sx={{ m: 1, width: "90%" }}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    referralMRN: e.target.value,
                  }))
                }
              />
            </Card>
            <Card sx={{ width: "90%", m: 1 }}>
              <Typography sx={{ m: 1 }}>'Opening the Loop' Notes</Typography>
              <TextField
              disabled={readOnly}
                id="standard-required"
                value={formValues.referralNotes}
                variant="standard"
                sx={{ m: 1, width: "90%" }}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    referralNotes: e.target.value,
                  }))
                }
              />
              {OTL.map((n) => (
                <Typography>{`${dayjs(n.date_created).format(
                  "MMMM D, YYYY"
                )} : ${n.notes}`}</Typography>
              ))}
            </Card>
          </Paper>
          <Paper
            sx={{
              textAlign: "center",
              backgroundColor: "#D6E6F2",
              padding: 2,
              width: "50%",
              m: 0.5,
            }}
          >
            <CardHeader title="Closing the Loop" />
            <Typography>
              Documentation to verify that prescribing physician returned
              patient notes to referring physician
            </Typography>
            <Card sx={{ width: "90%", m: 1 }}>
              <FormControl
              disabled={readOnly}
                sx={{ m: 2 }}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    requiredDocs: e.target.value,
                  }))
                }
              >
                <Typography>
                  Did the Prescribing Physician Return Required Documentation to
                  Entity Referring Physician? *
                </Typography>

                <RadioGroup
                disabled={readOnly}
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={formValues.requiredDocs}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Card>
            <Card sx={{ width: "90%", m: 1 }}>
              <Typography sx={{ m: 1 }}>Documentation Return Date *</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Typography sx={{ m: 1 }}>
                  <DesktopDatePicker
                  disabled={readOnly}
                    label="Documentation Date"
                    inputFormat="MM/DD/YYYY"
                    value={formValues.docDate}
                    onChange={handleChangeDocumentationReturnDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Typography>
              </LocalizationProvider>
            </Card>
            <Card sx={{ width: "90%", m: 1 }}>
              <FormControl onChange={handleSelectOutcome}
              disabled={readOnly}>
                <Typography component="span">
                  <Typography
                    id="demo-controlled-radio-buttons-group"
                    variant="subtitle2"
                    sx={{ fontSize: 12 }}
                  ></Typography>
                  <Typography>Outcome *</Typography>
                </Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={formValues.outcome}
                >
                  <FormControlLabel
                    value="Reverse/Requalify"
                    control={<Radio />}
                    label="Reverse/Requalify"
                  />
                  <FormControlLabel
                    value="Remain Non Matched"
                    control={<Radio />}
                    label="Remain Non Matched"
                  />
                  <FormControlLabel
                    value="Pending"
                    control={<Radio />}
                    label="Pending"
                  />
                  <FormControlLabel
                    value="Pending CE Review"
                    control={<Radio />}
                    label="Pending CE Review"
                  />
                </RadioGroup>
              </FormControl>
            </Card>
            <Card sx={{ width: "90%", m: 1 }}>
              <Typography sx={{ m: 1 }}>'Closing the Loop' Notes</Typography>
              <TextField
              disabled={readOnly}
                id="standard-required"
                value={formValues.prescribingNotes}
                variant="standard"
                sx={{ m: 1, width: "90%" }}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    prescribingNotes: e.target.value,
                  }))
                }
              />
              {CTL.map((n) =>
                n.note_type == "Unsubmit" ? (
                  <Typography>
                    <b>{`${dayjs(n.date_created).format("MMMM D, YYYY")} : ${
                      n.notes
                    }`}</b>
                  </Typography>
                ) : (
                  <Typography>{`${dayjs(n.date_created).format(
                    "MMMM D, YYYY"
                  )} : ${n.notes}`}</Typography>
                )
              )}
            </Card>
          </Paper>
        </Stack>
      </Stack>
      <Box
        m={1}
        display="flex"
        justifyContent={"flex-end"}
        alignItems="flex-end"
      >
        {!viewOnly && !readOnly && (
          <Stack direction={"row"}>
            <Button variant="contained" sx={{ m: 1 }} onClick={handleSave}>
              Save
            </Button>

            <Button
              variant="contained"
              sx={{ m: 1 }}
              onClick={handleSubmit}
              disabled={submitButton}
            >
              Submit
            </Button>
          </Stack>
        )}
        {readOnly && (
          <Button variant="contained" sx={{ m: 1 }} onClick={handleEditForm}>
            Edit
          </Button>
        )}
      </Box>
    </Paper>
  );
}
