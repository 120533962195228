import { baseApi } from "./baseService";
import { LogOut } from "../utils/logOut";

export function EncounterService() {
  const { loggedOut } = LogOut();
  const API = {
    submitEncounter: "/encounter/submit",
    updateEncounter: "/encounter/update",
    getEncounter: "/encounter/get",
    reAudit: "/encounter/reAudit"
  };

  const reAudit = (rx_prx_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.reAudit, {
          rx_prx_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  const submitEncounter = (
    rx_prx_id,
    encounterFound,
    encounterDate,
    visitId,
    location,
    MRN,
    outcome,
    notes,
    status,
    onSuccess,
    onError
  ) => {
    baseApi().then((api) =>
      api
        .post(API.submitEncounter, {
          rx_prx_id,
          encounterFound,
          encounterDate,
          visitId,
          MRN,
          outcome,
          notes,
          status,
          location,
          submit:true
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const updateEncounter = (
    rx_prx_id,
    encounterFound,
    encounterDate,
    visitId,
    location,
    MRN,
    outcome,
    notes,
    status,
    submit,
    onSuccess,
    onError
  ) => {
    baseApi().then((api) =>
      api
        .post(API.submitEncounter, {
          rx_prx_id,
          encounterFound,
          encounterDate,
          visitId,
          MRN,
          outcome,
          notes,
          status,
          location,
          submit:submit
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getEncounter = (rx_prx_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getEncounter, {
          rx_prx_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  return { submitEncounter, updateEncounter, getEncounter, reAudit };
}
