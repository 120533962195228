import React from "react";
import { UserContext } from "./userContext";
import { useQueueListener } from "../services/queueService";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  cardInfoAtom,
  complianceInfoAtom,
  pageAtom,
  referralInfoAtom,
  allDataLoadedSelector,
} from "../atoms/atoms";
import { AMQPWebSocketClient } from "@cloudamqp/amqp-client";

export const QueueContext = React.createContext({
  queueMessages: {
    EncounterMessages: "",
    ReferralMessages: "",
    NonmatchMessages: "",
    Nonmatch_ReferralMessages: "",
    ComplianceMessages: "",
  },
  closeEverything: () => {},
});

export const QueueProvider = ({ children }) => {
  const [queueMessages, setQueueMessages] = React.useState({
    EncounterMessages: [],
    ReferralMessages: [],
    NonmatchMessages: [],
    Nonmatch_ReferralMessages: [],
    ComplianceMessages: [],
  });
  const userCtx = React.useContext(UserContext);
  const [cardInfo, setCardInfo] = useRecoilState(cardInfoAtom);
  const [complianceInfo, setComplianceInfo] =
    useRecoilState(complianceInfoAtom);
  const [referralInfo, setReferralInfo] = useRecoilState(referralInfoAtom);
  const [page, setPage] = useRecoilState(pageAtom);
  var user = userCtx.user;
  const [db, setDb] = React.useState(null);
  const [conn, setConn] = React.useState(null);
  const [ch, setCh] = React.useState(null);
  const [eQ, setEQ] = React.useState(null);
  const [rQ, setRQ] = React.useState(null);
  const [nQ, setNQ] = React.useState(null);
  const [nrQ, setNRQ] = React.useState(null);
  const [cQ, setcQ] = React.useState(null);
  const [queuesReady, setQueuesReady] = React.useState(false);
  const dataLoaded = useRecoilValue(allDataLoadedSelector);
  const [nonmatchMap, setNonmatchMap] = React.useState();
  const [referralMap, setReferralMap] = React.useState();
  const [complianceMap, setComplianceMap] = React.useState();
  const url = "wss://woodpecker-01.rmq.cloudamqp.com/ws/amqp"; //`${tls ? "wss" : "ws"}://${window.location.host}`
  const amqp = new AMQPWebSocketClient(
    url,
    "dkcbvenf",
    "dkcbvenf",
    "GDTiILkPlicXZiOzFXTFthpysJ-6I2Z9"
  );

  // const url = "wss://creative-blond-ferret.rmq3.cloudamqp.com/ws/amqp";
  // const amqp = new AMQPWebSocketClient(
  //   url,
  //   "pyvksjtl",
  //   "pyvksjtl",
  //   "mHCN_ZAlytRF22TgjIfvtZKxoCUlivGL"
  // );

  const { ListenForQueueMessages } = useQueueListener();
  const establishConnection = async (retryCount = 0, maxRetries = 5) => {
    try {
      //    console.log(`Attempting to connect (attempt ${retryCount + 1})...`);
      const cn = await amqp.connect();
      console.log("Connection established successfully");
      setConn(cn);
      return cn;
    } catch (error) {
      //   console.error(`Connection attempt ${retryCount + 1} failed:`, error);
      if (retryCount < maxRetries) {
        //    console.log(`Retrying in 1 second...`);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return establishConnection(retryCount + 1, maxRetries);
      } else {
        //   console.error(`Failed to connect after ${maxRetries} attempts`);
        throw error;
      }
    }
  };
  const handleQueueMessage = (channel, msg) => {
    ListenForQueueMessages({
      msg,
      channel,
      onMessageReceived: (queueItem) => {
        //   console.log(`${channel} queue message received:`, queueItem);
        setQueueMessages((prevState) => {
          const messageKey = `${channel}Messages`;
          return {
            ...prevState,
            [messageKey]: [...(prevState[messageKey] || []), queueItem],
          };
        });
      },
    });
  };

  const getConnections = async () => {
    if (conn && queuesReady) {
      // console.log("Connection already exists, is open, and queues are ready");
      return;
    }
    try {
      // console.log("Attempting to establish connection...");

      const cn = await establishConnection();
      setConn(cn);

      // console.log("Creating channel...");
      const chan = await cn.channel();
      setCh(chan);

      //  console.log("Setting up queues...");

      const queuePromises = [
        setupQueue(chan, "Encounter", setEQ, nonmatchMap),
        setupQueue(chan, "Referral", setRQ, referralMap),
        setupQueue(chan, "Nonmatch", setNQ, nonmatchMap),
        setupQueue(chan, "Nonmatch_Referral", setNRQ, referralMap),
        setupQueue(chan, "Compliance", setcQ, complianceMap),
      ];

      await Promise.all(queuePromises);
    } catch (err) {
      // console.error("Failed to establish connection or set up queues:", err);
      setQueuesReady(false);
    }
  };

  const setupQueue = async (chan, queueName, setQueueState, idMap) => {
    try {
      // console.log(`Setting up ${queueName} queue...`);
      const queue = await (
        await chan.queue("", {
          durable: true,
          autoDelete: true,
          arguments: {
            "x-expires": 3600000,
          },
        })
      ).bind(`${db}-${queueName}-exchange`);
      const consumer = await queue.subscribe(
        { noAck: false, exclusive: true },
        (msg) => {
          try {
            // console.log(
            //   `Received message on ${queueName} queue:`,
            //   msg.bodyToString()
            // );
            handleQueueMessage(queueName, msg, idMap);
          } catch (error) {
            //  console.error(`Error processing ${queueName} message:`, error);
            msg.nack();
          }
        }
      );
      setQueueState(queue);

      await consumer.wait(1000);
      console.log(`${queueName} queue set up successfully`);
    } catch (err) {
      console.error(`Error setting up ${queueName} queue:`, err);
    }
  };

  const closeEverything = async () => {
    try {
      if (rQ) await rQ.delete();
      if (eQ) await eQ.delete();
      if (nQ) await nQ.delete();
      if (nrQ) await nrQ.delete();
      if (cQ) await cQ.delete();
      if (conn) await conn.close();
      setRQ(null);
      setEQ(null);
      setNQ(null);
      setNRQ(null);
      setcQ(null);
      setConn(null);
      setCh(null);
      setQueuesReady(false);
      //console.log("All connections and queues closed and deleted");
    } catch (error) {
      console.error("Error closing connections and queues:", error);
    }
  };

  const cleanupUnusedQueues = async () => {
    if (!ch) return;

    const queues = [rQ, eQ, nQ, nrQ, cQ];
    for (let queue of queues) {
      if (queue) {
        try {
          const { messageCount, consumerCount } = await queue.check();
          if (messageCount === 0 && consumerCount === 0) {
            await queue.delete();
            //  console.log(`Deleted unused queue: ${queue.name}`);
          }
        } catch (error) {
          // console.error(`Error checking/deleting queue ${queue.name}:`, error);
        }
      }
    }
  };
  React.useEffect(() => {
    const allQueuesReady = [eQ, rQ, nQ, nrQ, cQ].every(
      (queue) => queue !== null
    );

    if (allQueuesReady) {
      setQueuesReady(true);
      // console.log("All connections and queues set up successfully");
    } else {
      // console.error("Not all queues were set up successfully");
    }
  }, [eQ, rQ, nQ, nrQ, cQ]);
  React.useEffect(() => {
    if (!user || !dataLoaded) return;
    if (!db) {
      if (user.db_prefix) {
        //console.log("Setting db_prefix:", user.db_prefix);
        setDb(user.db_prefix);
      } else {
        // console.error("No db_prefix found in user info");
        return;
      }
    }
    let reconnectInterval;

    const checkConnection = async () => {
      if (!conn || conn.closed || !queuesReady) {
        // console.log(
        //   "Connection is closed, null, or queues are not ready. Attempting to reconnect..."
        // );

        setConn(null);
        setCh(null);
        setEQ(null);
        setRQ(null);
        setNQ(null);
        setNRQ(null);
        setcQ(null);
        setQueuesReady(false);

        try {
          await getConnections();
        } catch (error) {
          //console.error("Reconnection attempt failed:", error);
        }
      } else {
        // console.log("Connection is open and queues are ready");
      }
    };
    if (dataLoaded) {
      reconnectInterval = setInterval(checkConnection, 5000);
    }

    return () => {
      if (reconnectInterval) {
        clearInterval(reconnectInterval);
      }
    };
  }, [
    conn,
    queuesReady,
    user,
    db,
    dataLoaded,
    cardInfo,
    referralInfo,
    complianceInfo,
    page,
  ]);

  React.useEffect(() => {
    if (!user) {
      user = userCtx.getUser();
    }
  }, [cardInfo, complianceInfo, referralInfo, page]);
  React.useEffect(() => {
    const cleanupInterval = setInterval(cleanupUnusedQueues, 3600000);

    return () => {
      clearInterval(cleanupInterval);
      closeEverything();
    };
  }, []);

  return (
    <QueueContext.Provider value={{ queueMessages, closeEverything }}>
      {children}
    </QueueContext.Provider>
  );
};
