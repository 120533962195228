import { baseApi } from "./baseService";
import { LogOut } from "../utils/logOut";
export function FacilityService() {
  const { loggedOut } = LogOut();
  const API = {
    getAllFacilities: "/facility/getAll",
    get340BFacilities: "/facility/getAll340B",
  };

  const getAllFacilities = (onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAllFacilities, {})
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const get340BFacilities = (onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.get340BFacilities, {})
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  return { get340BFacilities, getAllFacilities };
}
