import { Route } from "react-router-dom";
import Encounter from "../screens/Encounter";
import Referral from "../screens/Referral";
import Compliance from "../screens/Compliance";

export function getHomeRoute(data) {
  switch (data) {
    case 4:
      return <Route path="/" element={<Encounter />} />;
    case 5:
      return <Route path="/" element={<Referral />} />;
    case 6:
      return <Route path="/" element={<Compliance />} />;
    default:
      return <Route path="/" element={<Encounter />} />;
  }
}

export function getHomePath(data) {
  switch (data) {
    case 4:
      return "Encounter Audit";
    case 5:
      return "Referral Audit";
    case 6:
      return "Compliance Audit";
    default:
      return "Encounter Audit";
  }
}

export function getHomeURL(data) {
  switch (data) {
    case 4:
      return "Audit";
    case 5:
      return "Referral";
    case 6:
      return "Compliance";
    default:
      return "Audit";
  }
}
