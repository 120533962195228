import React from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { UserContext } from "../../contexts/userContext";
import { AdminService } from "../../services/adminService";
import Swal from "sweetalert2";
import AdminDateRangePicker from "./AdminDateRangePicker";
import fileDownload from "js-file-download";

export default function AdminExportTPAReport({ setAnchorEl }) {
  const [TPAList, setTPAList] = React.useState([]);
  const [TPASelect, setTPASelect] = React.useState("");
  const [fromDate, setFromDate] = React.useState();
  const [toDate, setToDate] = React.useState();
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const { getTPAList, getTPAExport } = AdminService();
  const userCTX = React.useContext(UserContext);
  const { user } = userCTX;

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });

  React.useEffect(() => {
    if (!user || user.role_id != 3) return;
    getTPAList(
      (data) => {
        setTPAList(data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  const handleChangeTPA = (e) => {
    setTPASelect(e.target.value);
  };

  const handleSubmit = () => {
    if (!TPASelect) {
      swalWithBootstrapButtons.fire(
        "Error.",
        "Must have a TPA selected",
        "error"
      );
    }
    if (!fromDate || !toDate) {
      swalWithBootstrapButtons.fire(
        "Error.",
        "Must have a date range selected",
        "error"
      );
    }
    getTPAExport(
      TPASelect,
      fromDate,
      toDate,
      isSubmitted,
      (data, headers) => {
        const contentDisposition = headers["content-disposition"];
        let filename = "download.xlsx"; // Default filename if not found
        if (contentDisposition) {
          const matches = contentDisposition.match(/filename="([^"]+)"/);
          if (matches && matches.length > 1) {
            filename = matches[1];
          }
        }

        fileDownload(data, filename);
      },
      (err) => {
        setAnchorEl(null);
        swalWithBootstrapButtons.fire(
          "Error.",
          `There was an error processing your request or no records matched your query.`,
          "error"
        );
      }
    );
  };
  if (!user || user.role_id != 3) return;
  return (
    <Paper variant="outlined" sx={{ minWidth: 400 }}>
      <Typography variant="h5" textAlign={"center"} m={2}>
        TPA Export
      </Typography>
      <Typography
        component={"span"}
        sx={{ display: "flex", flexDirection: "column", m: 2 }}
      >
        {TPAList.length < 1 ? (
          <CircularProgress />
        ) : (
          <>
            <FormControl sx={{ m: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">TPA</InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={TPASelect}
                  label="Role"
                  onChange={handleChangeTPA}
                >
                  {TPAList.map((tpa, index) => (
                    <MenuItem value={tpa.tpa_id} key={tpa.tpa_id}>
                      {tpa.tpa_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormControl>
            <AdminDateRangePicker
              setFromDate={setFromDate}
              setToDate={setToDate}
            />
          </>
        )}
        <FormControl fullWidth>
          <Typography variant="overline">
            Mark these as submitted to the TPA?
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={isSubmitted}
                onChange={(e) => setIsSubmitted(!isSubmitted)}
              />
            }
            label={"Mark submitted"}
            key={"checkbox"}
          />
        </FormControl>
      </Typography>
      <Button variant="contained" onClick={handleSubmit} sx={{ width: "100%" }}>
        Download Export
      </Button>
    </Paper>
  );
}
