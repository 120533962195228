import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useRecoilState } from "recoil";
import { historyFilterAtom } from "../../atoms/atoms";

export default function RxFilter({ data, setFilterDataset }) {
  const [Rxs, setRxs] = React.useState([]);
  const [historyFilters, setHistoryFilters] = useRecoilState(historyFilterAtom);

  useEffect(() => {
    const uniqueRxs = new Set(data.map((d) => d.pk.rx_number));
    setRxs(Array.from(uniqueRxs));
  }, [data]);

  function onChange(newValue) {
    setHistoryFilters((prev) => ({ ...prev, rx_id: newValue, filtered: true }));
    if (newValue !== null) {
      const filteredData = data.filter((d) => {
        return d.pk.rx_number === newValue;
      });
      setFilterDataset(filteredData);
    } else {
      setFilterDataset(data);
    }
  }

  return (
    <Stack sx={{ width: "33%" }}>
      <Typography variant="overline">Filter by Rx</Typography>
      <Autocomplete
        sx={{ background: "#fff" }}
        options={Rxs}
        id="controlled-demo"
        value={historyFilters.rx_id}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        renderInput={(params) => {
          return <TextField {...params} label="Rx" />;
        }}
      />
    </Stack>
  );
}
