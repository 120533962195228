import dayjs from "dayjs";

export function prescriptionGen(data) {
  
  let allInfo = data.map((d) => {
    
    var newObject = {
      pk: {
        facility_id: d.facility_id,
        pharmacy_id: d.pharmacy_id,
        rx_number: d.rx_number,
        rx_fill_number: d.rx_fill_number,
      },
       rx_prx_id: d.rx_prx_id,
       scriptNumber: `${d.facility_id}.${d.pharmacy_id}.${d.rx_number}.${d.rx_fill_number}`,
       scriptNumberDisplay: `${d.pharmacy_name} ${d.rx_number} Fill ${d.rx_fill_number}`,
       rxNumber: d.rx_number,
       fills: d.rx_fill_number,
       pharmacy: d.pharmacy_name,
       patientName: `${d.patient_last_name}, ${d.patient_first_name}`,
       dob: d.patient_dob ? dayjs(d.patient_dob).format("MM/DD/YYYY") : "",
       patientMRN: d.patient_mrn,
       prescriberName: d.prescriber_name,
       prescriber340BStatus: d.prescriber_340b_status,
         //RxWrittenWhilePrescriberEligible: d.prescriber_eligibility, still needed?
       suspectEncounterDate: d.suspect_encounter_dates,
       suspectReferralDate: d.suspect_referral_dates,
       dateWritten: d.rx_date_written ? dayjs(d.rx_date_written).format("MM/DD/YYYY") : "",
       dateDispensed: dayjs(d.rx_date_dispensed).format("MM/DD/YYYY"),
       espSubmitDeadline: d.esp_submit_deadline
         ? dayjs(d.esp_submit_deadline).format("MM/DD/YYYY")
         : "N/A",

       nonmatchReason: d.nonmatch_reason ? d.nonmatch_reason : "N/A",
       primaryPayor: d.primary_payor,
       secondaryPayor: d.secondary_payor,
       drugName: d.ndc_display_name,
       drugStrength: d.ndc_strength,
       potentialSavings: d.est_340b_gross_savings,
       isAudited: false,
       espRestricted: d.rx_is_esp_restricted,
       potentialEncounters: d.potential_encounters,
         //restrictedNDC: d.ndcs.restricted_ndc,
       drugManufacturer: d.manufacturer_display_name,
       patientEncounter: d.patient_encounter_id,
         //location: d.CE,
       status: d.status ? d.status : -1,
       notes: d.notes,
       checkedOut: false,
       qualified_correctly: d.qualified_correctly ?d.qualified_correctly : false,
       isReaudit: d.isReaudit,
       audit_count : 0,
       status_start: 1

    };
    return newObject;
    
  

  });
  return allInfo;
}

export function historyGen(data, type) {
  let allInfo = data.map((d) => {
    
    var newObject = {
      pk: {
        facility_id: d.facility_id,
        pharmacy_id: d.pharmacy_id,
        rx_number: d.rx_number,
        rx_fill_number: d.rx_fill_number,
      },
      rx_prx_id: d.rx_prx_id,
      pharmacy: d.pharmacy_name,
      patientName: `${d.patient_last_name}, ${d.patient_first_name}`,
      dateDispensed: dayjs(d.rx_date_dispensed).format("MM/DD/YYYY"),
      fills: d.rx_fill_number,
      dob: d.patient_dob,
      prescriberName: d.prescriber_name,
      potentialSavings: d.est_340b_gross_savings,
      dateWritten: dayjs(d.rx_date_written).format("MM/DD/YYYY"),
      nonmatchReason: d.nonmatch_reason ? d.nonmatch_reason : "N/A",
      primaryPayor: d.primary_payor,
      secondaryPayor: d.secondary_payor,
      auditor: `${d.user_last_name}, ${d.user_first_name}`,
      outcome: d.outcome,
      auditType: type,
      ndc: d.rx_ndc,
      numAudits: d.numAudits,
      auditDateCreated:
        type == "Encounter"
          ? d.date_created
          : type == "Referral"
          ? d._date_created
          : type == "Compliance"
          ? d.date_created
          : d.check_out_time,
      auditDateUpdated:
        type == "Encounter"
          ? d.date_updated
          : type == "Referral"
          ? d.date_updated
          : type == "Compliance"
          ? d.date_updated
          : d.check_in_time,
      auditTimeMinutes:
        type == "Encounter"
          ? Math.ceil(
              Math.abs(
                new Date(d.date_created) -
                  new Date(d.date_updated)
              ) / 60000
            )
          : type == "Referral"
          ? Math.ceil(
              Math.abs(
                new Date(d.date_created) -
                  new Date(d.date_updated)
              ) / 60000
            )
          : type == "Compliance"
          ? Math.ceil(
              Math.abs(
                new Date(d.date_created) -
                  new Date(d.date_updated)
              ) / 60000
            )
          : Math.ceil(
              Math.abs(new Date(d.check_out_time) - new Date(d.check_in_time)) /
                60000
            ),
      status: type == "Compliance" ? d.status : d.status,
      complianceQual:
        type == "Compliance" ? d.qualified_correctly : null,
      complianceNote: type == "Compliance" ? d.notes : null,

    };
    return newObject;
  });
  return allInfo;
}
