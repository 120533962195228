import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box } from "@mui/material";

export default function StatusFilter({
  data,
  status,
  setStatus,
  setFilterOptions,
}) {
  const [selectItems, setSelectItems] = React.useState([]);
  const [statusFilter, setStatusFilter] = React.useState(status);
  const statusMap = {
    1: "New",
    2: "In Process",
    3: "Completed",
    4: "Submitted",
    5: "Cancelled",
    6: "Pending CE Review",
  };

  React.useEffect(() => {
    let statusMap = data.map((p) => p.status);
    let uniqueStatuses = [...new Set(statusMap)];
    setSelectItems(uniqueStatuses);
  }, [data]);

  const handleChange = (event) => {
    setStatus([statusMap[event.target.value]]);
    setStatusFilter(event.target.value);
    setFilterOptions((prev) => ({ ...prev, status_id: event.target.value }));
  };

  return (
    <Box className="filterBar">
      <FormControl sx={{ m: 1, minWidth: 165 }}>
        <InputLabel id="demo-simple-select-autowidth-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={statusFilter}
          onChange={handleChange}
          autoWidth
          label="Status"
          placeholder="Status"
          sx={{ backgroundColor: "whitesmoke" }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>

          {selectItems.map((item, index) => {
            return (
              <MenuItem key={index} value={item}>
                {statusMap[item]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
