const { REACT_APP_ENV } = process.env;

export default {

    urls: {
        base:
            REACT_APP_ENV === 'development'
                ? 'http://localhost:5000/api' //'https://localhost:7102/api'
                : REACT_APP_ENV === 'production'
                    ? 'https://api.proxsysrx.com:5000/api'
                    : 'https://test-api.proxsysrx.com:5000/api'//'https://testapi-843776312.us-east-2.elb.amazonaws.com:5000/api'
    }
}