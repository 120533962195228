import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { Box, TextField, Autocomplete } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  auditFilterAtom,
  referralFilterAtom,
  staticDataAtom,
} from "../../atoms/atoms";

export default function PharmacyFilter({ data }) {
  const [staticData, setStaticData] = useRecoilState(staticDataAtom);
  // const [selectItems, setSelectItems] = React.useState(
  //   staticData.referralPharmacies
  // );
  const [filterOptions, setFilterOptions] = useRecoilState(referralFilterAtom);

  const handleChange = (event, values) => {
    setFilterOptions((prev) => ({
      ...prev,
      pharmacy: values,
    }));
  };

  return (
    <Box className="filterBar">
      <FormControl sx={{ m: 1, minWidth: 165, p: 1 }}>
        <Autocomplete
          multiple // Enable multiple selection
          value={filterOptions.pharmacy || []} // Ensure value is an array
          onChange={handleChange}
          id="checkboxes-tags-demo"
          options={staticData.referralPharmacies}
          getOptionLabel={(option) => option}
          style={{ width: 250, background: "whitesmoke" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Pharmacies"
              placeholder="Pharmacies"
            />
          )}
        />
      </FormControl>
    </Box>
  );
}
