import axios from "axios";

const urls = require('../config').default.urls;


export const tenantValidate = async (prefix, onSuccess, onError) => { 
  await axios
    .post(
      `${urls.base}/tenant/validate`,


      { prefix }
    )
    .then(({ data }) => {
//     localStorage.setItem('token', data.token);
     
      onSuccess(data);
      
    })
    .catch(({ response }) => {
      console.log(response);
      onError && onError(response);
    });
};

export const login = async (prefix, userName, password, tenant, onSuccess, onError) => { 
  //var  tennant = "conway";
  await axios
    .post(
   `${urls.base}/login`,


      { userName, password, tenant }
    )
    .then(({ data }) => {
      localStorage.removeItem('token');
      localStorage.setItem('token', data.token);
     

      onSuccess(data);
    })
    .catch(({ response }) => {
      console.log(response);
      onError && onError(response);
    });
};

export const getUserInfo = async(onSuccess,onError) => {
  axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('token')}`};
  await axios
    .get(
      `${urls.base}/Login/loggedInUser`
    )
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch(({ response }) => {
      console.log(response);
      onError && onError(response);
    });
};