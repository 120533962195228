import React from "react";
import { Container, Typography, AppBar, Toolbar, Paper } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const ErrorPage = () => {
  return (
    <div>
      <Container component="main" maxWidth="sm" style={{ marginTop: "8rem" }}>
        <Paper elevation={3} style={{ padding: "2rem", textAlign: "center" }}>
          <ErrorOutlineIcon style={{ fontSize: "4rem", color: "red" }} />
          <Typography variant="h4" component="h1" gutterBottom>
            Page not available
          </Typography>
          <Typography variant="body1">
            If you believe you are seeing this page in error, please contact
            your administrator.
          </Typography>
        </Paper>
      </Container>
    </div>
  );
};

export default ErrorPage;
