import React from "react";
import PrescriberNameFilter from "./EncounterPrescriberNameFilter";
import ScriptFilter from "./EncounterScriptFilter";
import EncounterToggle from "./EnounterToggle";
import {
  Box,
  Card,
  Typography,
  Collapse,
  Divider,
  Paper,
  Button,
  CardActionArea,
  CircularProgress,
} from "@mui/material";
import ReferralToggle from "../referral/ReferralToggle";
import { Stack } from "@mui/system";
import DateRangePickerCustom from "./EncounterDateRangePickerCustom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import StatusFilter from "./EncounterStatusFilter";
import VisitFilter from "./EncounterVisitFilter";
import { NonmatchedService } from "../../services/nonmatchedService";
import { useRecoilState } from "recoil";
import {
  auditFilterAtom,
  cardInfoAtom,
  pageAtom,
  staticDataAtom,
} from "../../atoms/atoms";
import RestrictedESPToggle from "./EncounterRestrictedESPToggle";
import ExclusivePrescriberFilter from "./EncounterExclusivePrescriberFilter";
import { prescriptionGen } from "../../utils/dictGen";
import PharmacyFilter from "./EncounterPharmacyFilter";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
const statusMap = {
  1: "New",
  2: "In Process",
  3: "Completed",
  4: "Submitted",
  5: "Cancelled",
  6: "Pending CE Review",
};
export default function FilterBar({ reset, checkIn }) {
  const [data, setData] = useRecoilState(cardInfoAtom);
  const [filters, setFilters] = React.useState([]);
  const [filterOptions, setFilterOptions] = useRecoilState(auditFilterAtom);
  const [loading, setLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(filterOptions.expanded);
  const [pages, setPages] = useRecoilState(pageAtom);
  const [restrictedToggle, setRestrictedToggle] = React.useState(
    filterOptions.rx_is_esp_restricted
  );
  const [exclusivePrescriber, setExclusivePrescriber] = React.useState(
    filterOptions.exclusive_prescriber
  );
  const [status, setStatus] = React.useState(
    statusMap[filterOptions.status_id]
  );
  const [scripts, setScripts] = React.useState(filterOptions.rx_id);
  const { getAllNonmatched, getFiltered, getAllPrescribers, getAllPharmacies } =
    NonmatchedService();
  const [staticData, setStaticData] = useRecoilState(staticDataAtom);
  const PER_PAGE = 15;

  const searchFilterOptions = () => {
    setLoading(true);
    setFilterOptions((prev) => ({
      ...prev,
      filtered: true,
    }));

    getAllNonmatched(
      PER_PAGE,
      pages.filteredPage,
      filterOptions,
      (data) => {
        const allInfo = prescriptionGen(data.all);
        setData(allInfo);
        const totalPages = Math.ceil(data.count / PER_PAGE);
        setPages((prev) => ({
          ...prev,
          filteredNumberOfPages: totalPages,
        }));

        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
    setFilterOptions((prev) => ({
      ...prev,
      expanded: true,
    }));

    getAllPrescribers((data) => {
      const prescriberMap = data[0].map((p) => p.prescriber_name);
      setStaticData((prev) => ({
        ...prev,
        prescribers: prescriberMap,
      }));
    });
    getAllPharmacies((data) => {
      const pharmacyMap = data[0].map((p) => p.pharmacy_name);
      setStaticData((prev) => ({
        ...prev,
        pharmacies: pharmacyMap,
      }));
    });
  };

  const handleClear = () => {
    setFilterOptions({
      prescriber: "",
      status_id: "",
      rx_id: "",
      visit_id: "",
      start_date: "",
      end_date: "",
      rx_is_esp_restricted: "",
      exclusive_prescriber: "",
      pharmacy: "",
      expanded: false,
      filtered: false,
    });
    setPages((prev) => ({
      ...prev,
      filteredPage: 1,
      page: 1,
      filteredNumberOfPages: 1,
    }));

    reset();
    checkIn();
  };

  React.useEffect(() => {
    if (!filterOptions.filtered) return;
    searchFilterOptions();
  }, [pages.filteredPage]);

  React.useEffect(() => {
    setFilters([
      { filter: "Prescriber", value: filterOptions.prescriber },
      //{ filter: "Visit ID", value: filterOptions.visit_id },
      { filter: "Status", value: statusMap[filterOptions.status_id] },
      { filter: "Prescription Number", value: filterOptions.rx_id },
      {
        filter: "Dates",
        value: filterOptions.start_date
          ? [
              `Start: ${new Date(filterOptions.start_date).toDateString()} `,
              `End: ${new Date(filterOptions.end_date).toDateString()}`,
            ]
          : [],
      },
      {
        filter: "Exclusive Prescriber",
        value: filterOptions.exclusive_prescriber,
      },
      { filter: "Restricted ESP", value: filterOptions.rx_is_esp_restricted },
      { filter: "Pharmacy", value: filterOptions.pharmacy },
    ]);
  }, [filterOptions]);

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <Box pt={5}>
      {/* <Stack direction={"row"} spacing={2} justifyContent="center">
        <EncounterToggle data={data} setData={setData} reset={reset} />
        <ReferralToggle data={data} setData={setData} reset={reset} />
      </Stack> */}
      <Card>
        <CardActionArea onClick={handleExpandClick}>
          <Typography
            color="#282c34"
            variant={"overline"}
            paddingRight={5}
            visibility={"visible"}
            sx={{ fontSize: 14 }}
          >
            Encounter Audit Filters
          </Typography>

          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            sx={{ fontFamily: "unset", fontSize: "medium" }}
          >
            <ExpandMoreIcon titleAccess="Audit Filters"></ExpandMoreIcon>
          </ExpandMore>
        </CardActionArea>
        <Divider orientation="horizontal" flexItem />
        <Stack direction={"row"} justifyContent={"space-evenly"}>
          <IconButton
            onClick={handleClear}
            aria-label="delete"
            color="primary"
            sx={{ justifyContent: "flex-start", fontSize: 14 }}
          >
            <DeleteIcon fontSize="medium" />
          </IconButton>

          {filters.map((f, index) => {
            return (
              <Stack justifyItems={"center"} alignItems={"center"}>
                <Typography
                  variant="h3"
                  key={index}
                  padding={3}
                  sx={{ fontSize: 16, display: "flex" }}
                >
                  {f.filter}:
                </Typography>
                <Divider orientation="horizontal" flexItem />
                {/* {f.value.map((v, index) => {
                  return ( */}
                <Typography
                  variant="overline"
                  key={index}
                  padding={1}
                  sx={{
                    fontSize: 12,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {f.value && Array.isArray(f.value) ? (
                    <Stack direction={"column"}>
                      {f.value.map((v, index) => (
                        <Typography variant="overline" key={index}>
                          {v}
                        </Typography>
                      ))}
                    </Stack>
                  ) : (
                    f.value
                  )}
                </Typography>
                {/* );
                })} */}
              </Stack>
            );
          })}
        </Stack>
      </Card>
      <Collapse in={expanded} timeout="auto">
        <Paper>
          <DateRangePickerCustom
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
          />
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              backgroundColor: "#B9D7EA",
              pb: 1,
            }}
          >
            <Stack direction={"row"}>
              <Typography
                component={"span"}
                sx={{ width: "50%", backgroundColor: "#B9D7EA" }}
              >
                <PrescriberNameFilter />
                <ExclusivePrescriberFilter
                  setExclusivePrescriber={setExclusivePrescriber}
                  exclusivePrescriber={exclusivePrescriber}
                  setFilterOptions={setFilterOptions}
                />
                <StatusFilter
                  data={data}
                  status={filterOptions.status_id}
                  setStatus={setStatus}
                  setFilterOptions={setFilterOptions}
                />
              </Typography>
              <Typography component={"span"} sx={{ width: "50%" }}>
                <ScriptFilter
                  setScripts={setScripts}
                  filterOptions={filterOptions}
                  setFilterOptions={setFilterOptions}
                />
                <PharmacyFilter />
                <RestrictedESPToggle
                  restrictedToggle={restrictedToggle}
                  setRestrictedToggle={setRestrictedToggle}
                  setFilterOptions={setFilterOptions}
                />
              </Typography>
            </Stack>
          </Card>

          <Stack direction={"row"} justifyContent={"center"}>
            <Button
              variant="contained"
              sx={{ m: 1 }}
              onClick={searchFilterOptions}
            >
              Submit
            </Button>
            <Button variant="contained" sx={{ m: 1 }} onClick={handleClear}>
              Clear All
            </Button>
          </Stack>
        </Paper>
      </Collapse>
    </Box>
  );
}
