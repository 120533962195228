import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { LogOut } from "../utils/logOut";
import { UserContext } from "../contexts/userContext";
import { useNavigate } from "react-router-dom";
import { AuditService } from "../services/auditService";

export const TermsAndConditions = () => {
  const [isAgreeEnabled, setIsAgreeEnabled] = React.useState(false);
  const [isContinueEnabled, setIsContinueEnabled] = React.useState(false);
  const termsRef = React.useRef(null);
  const { loggedOut } = LogOut();
  const userCtx = React.useContext(UserContext);
  const navigate = useNavigate();
  const { agreeToTerms } = AuditService();

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = termsRef.current;
    if (scrollTop + clientHeight === scrollHeight) {
      setIsAgreeEnabled(true);
    }
  };
  const handleCancel = () => {
    loggedOut("Disagree");
  };

  const handleAgree = () => {
    if (!isAgreeEnabled) return;
    setIsContinueEnabled(true);
  };

  const handleContinue = () => {
    let user = userCtx.user;
    user.terms_date = new Date().toISOString();
    userCtx.saveUser(user);
    agreeToTerms(
      true,
      (data) => {
        //console.log(data);
        navigate("/Audit");
      },
      (err) => console.log(err)
    );
  };

  return (
    <Paper>
      <Card sx={{ height: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardMedia
                component={"img"}
                src="https://www.proxsysrx.com/wp-content/uploads/2021/09/ProxsysRx-Logo.png"
                sx={{ width: "15%", alignSelf: "center" }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <CardHeader
              title="340B Pro Terms and Conditions"
              sx={{ textAlign: "center" }}
              titleTypographyProps={{ variant: "h4" }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <CardContent sx={{ height: "50%" }}>
              <div
                ref={termsRef}
                onScroll={handleScroll}
                style={{ height: "300px", overflow: "auto" }}
              >
                <Typography variant="h5">TERMS OF USE </Typography>
                <Typography variant="h6">
                  Last updated December 21, 2023
                </Typography>
                <Typography pt={3}>
                  <b>AGREEMENT TO TERMS</b>
                </Typography>
                <Typography>
                  These Terms of Use (<b>“Terms”</b>) are entered into by and
                  between you and ProxsysRx, Inc. (
                  <b>"Company," "we," "us," "our"</b>), a Delaware corporation
                  with principal offices located at 1500 Urban Center Drive,
                  Suite 325, Birmingham, AL 35242. These Terms of Use, together
                  with (1) the agreement between us and the company you
                  represent governing our provision of pharmacy related services
                  (the <b>“Services Agreement”</b>); and (2) the Business
                  Associate Agreement between us and the company you represent
                  (the <b>“BAA”</b>), govern your access to and use of the
                  website 340bpro.proxsysrx.com, 340B Pro, a cloud based
                  application used to review prescription claims for 340B
                  opportunity and compliance, and all other content,
                  functionality and services offered on or through
                  340bpro.proxsysrx.com (collectively, the <b>"Services"</b>
                  ).
                </Typography>
                <Typography pt={3}>
                  You can contact us by phone at 205-533-9119, email at
                  340bpro@proxsysrx.com, or by regular mail to 1500 Urban Center
                  Drive, Suite 325, Birmingham, AL 35242, United States.
                </Typography>

                <Typography pt={3}>
                  {" "}
                  These Terms constitute a legally binding agreement. You agree
                  that by accessing the Services or clicking “I agree,” (i) you
                  have read, understood, and agreed to be bound by all of these
                  Legal Terms; (ii) if you are accessing or using the Services
                  on behalf of a company or other legal entity, you have the
                  authority to, and hereby do, legally bind such company or
                  other legal entity to these Terms; and (iii) you are of the
                  age of legal majority in your jurisdiction. IF YOU DO NOT
                  AGREE WITH ALL OF THESE TERMS, THEN YOU ARE EXPRESSLY
                  PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE
                  USE IMMEDIATELY.
                </Typography>
                <Typography pt={3}>
                  We reserve the right, in our sole discretion, to make changes
                  or modifications to these Terms at any time and for any
                  reason. While we will provide notice of material changes in
                  accordance with applicable law, all other changes are
                  effective immediately when we post them, and apply to all
                  access to and use of the Services thereafter. Your continued
                  use of the Services following the posting of revised Terms
                  means that you accept and agree to the changes. You are
                  expected to check this page frequently so you are aware of any
                  changes, as they are binding on you.
                </Typography>
                <Typography pt={3}>
                  We recommend that you print a copy of these Terms for your
                  records.
                </Typography>
                <Typography pt={3} variant="h6">
                  1. OUR SERVICES{" "}
                </Typography>
                <Typography pt={3}>
                  We reserve the right to withdraw, disable or amend the
                  Services, in our sole discretion without notice. We will not
                  be liable if for any reason all or any part of the Services is
                  unavailable at any time or for any period. From time to time,
                  we may restrict access to some parts of the Services, or all
                  of the Services, to users.
                </Typography>
                <Typography pt={3}>
                  We may from time to time in our sole discretion develop and
                  provide updates to the Services, which may include upgrades,
                  bug fixes, patches, other error corrections, and/or new
                  features (collectively, including related documentation,{" "}
                  <b>"Updates"</b>
                  ). Updates may also modify or delete in their entirety certain
                  features and functionality. You agree that we have no
                  obligation to provide any Updates or to continue to provide or
                  enable any particular features or functionality. All Updates
                  will be deemed to be part of the Services and shall be subject
                  to all terms and conditions of these Terms.
                </Typography>
                <Typography pt={3}>
                  If you choose, or are provided with, a user name, password, or
                  any other piece of information as part of our security
                  procedures, you must treat such information as confidential,
                  and you must not disclose it to any other person or entity.
                  You also acknowledge that your account is personal to you and
                  agree not to provide any other person with access to the
                  Services or portions of it using your user name, password, or
                  other security information. You agree to notify us immediately
                  of any unauthorized access to or use of your user name or
                  password or any other breach of security. You also agree to
                  ensure that you exit from your account at the end of each
                  session. You should use particular caution when accessing your
                  account from a public or shared computer so that others are
                  not able to view or record your password or other personal
                  information.
                </Typography>
                <Typography pt={3}>
                  We have the right to disable any user name, password, or other
                  identifier, whether chosen by you or provided by us, at any
                  time in our sole discretion for any or no reason, including
                  without limitation if, in our opinion, you have violated any
                  provision of these Terms.
                </Typography>
                <Typography variant="h6" pt={3}>
                  2. INTELLECTUAL PROPERTY RIGHTS{" "}
                </Typography>
                <Typography pt={3}>
                  <b> Our intellectual property </b>
                </Typography>
                <Typography pt={3}>
                  We are the owner or the licensee of and will retain all right,
                  title and interest in and to all intellectual property rights
                  in our Services, including without limitation all source code,
                  documentation, databases, functionality, software, website
                  designs, audio, video, text, photographs, and graphics in the
                  Services and any Updates (collectively, the <b>"Content"</b>),
                  as well as the trademarks, service marks, and logos contained
                  therein (the
                  <b>"Marks"</b>). In the event that you acquire any right,
                  title or interest in or to the Content or Marks, by operation
                  of law or otherwise, you hereby irrevocably assign all such
                  right, title and interest to us.
                </Typography>
                <Typography pt={3}>
                  Our Content and Marks are protected by copyright and trademark
                  laws (and various other intellectual property rights and
                  unfair competition laws) and treaties in the United States and
                  around the world.
                </Typography>
                <Typography pt={3}>
                  The Content and Marks are provided in or through the Services
                  "AS IS" for your internal business purposes only.
                </Typography>
                <Typography pt={3}>
                  You hereby irrevocably grant all rights and permissions in or
                  relating to any data that you input, transmit or upload into
                  the Services (<b>“Your Data”</b>) as are necessary or useful
                  to us, our subcontractors and personnel to enforce this
                  Agreement and to perform all obligations hereunder. You agree
                  that we may collect and compile data and information related
                  to your use of the Services that may be used by us for any
                  purpose permitted under applicable law, including without
                  limitation for the purpose of compiling statistical and
                  performance information related to the provision and operation
                  of the Services and the development of improvements to the
                  Services.
                </Typography>
                <Typography pt={3}>
                  <b>Your use of our Services </b>
                </Typography>
                <Typography pt={3}>
                  Subject to your compliance with these Terms, we grant you a
                  non-exclusive, non-transferable, non-sublicensable and
                  revocable right to access and use the Services solely for your
                  internal business purposes.
                </Typography>
                <Typography pt={3}>
                  Except as expressly set out in this Section or elsewhere in
                  these Terms, no part of the Services and no Content or Marks
                  may be copied, reproduced, aggregated, republished, uploaded,
                  posted, publicly displayed, encoded, translated, transmitted,
                  distributed, sold, licensed, or otherwise exploited by you for
                  any purpose whatsoever, without our express prior written
                  permission.
                </Typography>
                <Typography pt={3}>
                  No right, title or interest in or to the Services or any
                  Content or Marks is transferred to you, and we reserve all
                  rights not expressly granted to you in and to the Services,
                  Content, and Marks.
                </Typography>
                <Typography pt={3}>
                  Any breach of this Section will constitute a material breach
                  of these Terms and your right to use our Services will
                  automatically terminate immediately.
                </Typography>
                <Typography pt={3}>
                  <b>Your submissions </b>
                </Typography>
                <Typography pt={3}>
                  <b>Submissions:</b> By directly sending us any question,
                  comment, suggestion, idea, feedback, or other information
                  about the Services ("Submissions"), you hereby irrevocably
                  assign to us all intellectual property rights in such
                  Submission. You agree that we shall own any such Submission
                  and be entitled to its unrestricted use and dissemination for
                  any lawful purpose, commercial or otherwise, without
                  acknowledgment or compensation to you.
                </Typography>
                <Typography pt={3} variant="h6">
                  3. USER REPRESENTATIONS{" "}
                </Typography>
                <Typography pt={3}>
                  By using the Services, you represent and warrant that: (1) you
                  own or otherwise have all necessary rights and consents in or
                  relating to Your Data, so that, as received and used by us, it
                  does not and will not infringe, misappropriate, or otherwise
                  violate any intellectual property rights, privacy rights,
                  rights relating to PHI (as defined below), or any other right
                  of any third party or violate any applicable law; (2) you will
                  not access the Services through automated or non-human means,
                  whether through a bot, script or otherwise; (3) you will not
                  use the Services for any illegal or unauthorized purpose; and
                  (4) your use of the Services will not violate any applicable
                  law or regulation.
                </Typography>
                <Typography pt={3}>
                  If you provide any information that is untrue, inaccurate, not
                  current, or incomplete, we have the right to suspend or
                  terminate your account and refuse any and all current or
                  future use of the Services (or any portion thereof).
                </Typography>
                <Typography pt={3} variant="h6">
                  4. PROHIBITED ACTIVITIES{" "}
                </Typography>
                <Typography pt={3}>
                  You may not access or use the Services for any purpose other
                  than that for which we make the Services available.
                </Typography>
                <Typography pt={3}>
                  As a user of the Services, you agree not to:
                  <div style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                    <Typography pt={1}>
                      {" "}
                      • Rent, lease, lend, sell, sublicense, assign, distribute,
                      publish, transfer, or otherwise make available the
                      Services to any third party, including on or in connection
                      with the internet or any time-sharing, service bureau,
                      software as a service, cloud or other technology or
                      service.{" "}
                    </Typography>
                    <Typography pt={1}>
                      {" "}
                      • Systematically retrieve data or other content from the
                      Services to create or compile, directly or indirectly, a
                      collection, compilation, database, or directory without
                      written permission from us.{" "}
                    </Typography>
                    <Typography pt={1}>
                      {" "}
                      • Trick, defraud, or mislead us and other users,
                      especially in any attempt to learn sensitive account
                      information such as user passwords.
                    </Typography>
                    <Typography pt={1}>
                      {" "}
                      • Circumvent, disable, or otherwise interfere with
                      security-related features of the Services, including
                      features that prevent or restrict the use or copying of
                      any Content or enforce limitations on the use of the
                      Services and/or the Content contained therein.{" "}
                    </Typography>
                    <Typography pt={1}>
                      {" "}
                      • Disparage, tarnish, or otherwise harm, in our opinion,
                      us and/or the Services.
                    </Typography>
                    <Typography pt={1}>
                      {" "}
                      • Use any information obtained from the Services in order
                      to harass, abuse, or harm another person.
                    </Typography>
                    <Typography pt={1}>
                      {" "}
                      • Use the Services in a manner inconsistent with any
                      applicable laws or regulations.
                    </Typography>
                    <Typography pt={1}>
                      {" "}
                      • Engage in unauthorized framing of or linking to the
                      Services.
                    </Typography>
                    <Typography pt={1}>
                      {" "}
                      • Upload or transmit (or attempt to upload or to transmit)
                      viruses, Trojan horses, or other harmful code that
                      interferes with any party’s uninterrupted use and
                      enjoyment of the Services or modifies, impairs, disrupts,
                      alters, or interferes with the use, features, functions,
                      operation, or maintenance of the Services.
                    </Typography>
                    <Typography pt={1}>
                      {" "}
                      • Engage in any automated use of the Services, such as
                      using any data mining, robots, or similar data gathering
                      and extraction tools.
                    </Typography>
                    <Typography pt={1}>
                      • Delete the copyright or other proprietary rights notice
                      from any Content.
                    </Typography>
                    <Typography pt={1}>
                      • Attempt to impersonate another user or person or use the
                      username of another user.
                    </Typography>
                    <Typography pt={1}>
                      • Upload or transmit (or attempt to upload or to transmit)
                      any material that acts as a passive or active information
                      collection or transmission mechanism, including without
                      limitation, clear graphics interchange formats ("gifs"),
                      1×1 pixels, web bugs, cookies, or other similar devices
                      (sometimes referred to as "spyware" or "passive collection
                      mechanisms" or "pcms").
                    </Typography>
                    <Typography pt={1}>
                      • Interfere with, disrupt, or create an undue burden on
                      the Services or the networks or services connected to the
                      Services.
                    </Typography>
                    <Typography pt={1}>
                      • Harass, annoy, intimidate, or threaten any of our
                      employees or agents engaged in providing any portion of
                      the Services to you.
                    </Typography>
                    <Typography pt={1}>
                      • Attempt to bypass any measures of the Services designed
                      to prevent or restrict access to the Services, or any
                      portion of the Services.
                    </Typography>
                    <Typography pt={1}>
                      • Copy, modify, adapt or create derivative works of the
                      Services, its software or source code.
                    </Typography>
                    <Typography pt={1}>
                      • Except as permitted by applicable law, decipher,
                      decompile, disassemble, or reverse engineer any of the
                      software or source code comprising or in any way making up
                      a part of the Services.
                    </Typography>
                    <Typography pt={1}>
                      • Except as may be the result of standard search engine or
                      Internet browser usage, use, launch, develop, or
                      distribute any automated system, including without
                      limitation, any spider, robot, cheat utility, scraper, or
                      offline reader that accesses the Services, or use or
                      launch any unauthorized script or other software.
                    </Typography>
                    <Typography pt={1}>
                      • Use the Services for purposes of competitive analysis or
                      as part of any effort to compete with us.
                    </Typography>
                  </div>
                </Typography>
                <Typography pt={3} variant="h6">
                  5. ALTERATION OF DATA{" "}
                </Typography>
                <Typography pt={3}>
                  You acknowledge and understand that: (1) the Services are used
                  by us to provide pharmacy related services to you under the
                  Services Agreement; (2) we are granting you access to the
                  Services solely for your convenience and to allow you to
                  directly view your claims related information; (3) although we
                  do not recommend that you do so, you have the capability to
                  view, edit, alter and modify your claims related information;
                  and (4) inaccurate, incorrect or incomplete data that you
                  submit through the Services or inaccurate, incorrect or
                  incomplete alterations or modifications of our data that you
                  make through the Services may impact our ability to provide
                  services to you. You acknowledge and agree that we are not
                  liable for any increased costs, damages, losses or liabilities
                  that you may incur, any inaccurate benefits or claims data, or
                  for any inability of us to perform services, due to your
                  alteration or modification of claims related data through the
                  Services. You are solely responsible for any and all changes,
                  modifications or edits that you make to claims data through
                  the Services.
                </Typography>
                <Typography pt={3} variant="h6">
                  6. SERVICES MANAGEMENT{" "}
                </Typography>
                <Typography pt={3}>
                  We reserve the right, but not the obligation, to: (1) monitor
                  the Services for violations of these Terms; (2) take
                  appropriate legal action against anyone who, in our sole
                  discretion, violates the law or these Terms, including without
                  limitation, reporting such user to law enforcement
                  authorities; (3) in our sole discretion and without
                  limitation, refuse, restrict access to, limit the availability
                  of, or disable your access to the Services or any portion
                  thereof; and (4) otherwise manage the Services in a manner
                  designed to protect our rights and property and to facilitate
                  the proper functioning of the Services.
                </Typography>
                <Typography pt={3} variant="h6">
                  7. PHI AND CONFIDENTIAL INFORMATION{" "}
                </Typography>
                <Typography pt={3}>
                  Any and all Protected Health Information or PHI (as defined in
                  the BAA) that you input, upload or otherwise transmit to us
                  through the Services is subject to the terms and conditions of
                  the BAA between us and the company or other legal entity that
                  you represent. All confidential information disclosed by one
                  party to the other party shall be subject to the applicable
                  confidentiality obligations under the Services Agreement.
                </Typography>
                <Typography pt={3} variant="h6">
                  8. TERM AND TERMINATION{" "}
                </Typography>
                <Typography pt={3}>
                  These Terms shall remain in full force and effect while you
                  use the Services, until terminated as permitted herein.
                  WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS, WE MAY,
                  IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY,
                  TERMINATE THESE TERMS AND DENY ACCESS TO AND USE OF THE
                  SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO YOU FOR
                  ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR
                  BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED
                  IN THESE TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
                  TERMINATE YOUR USE OF THE SERVICES AT ANY TIME, WITHOUT
                  WARNING AND WITHOUT CAUSE, IN OUR SOLE DISCRETION.
                </Typography>
                <Typography pt={3}>
                  Upon termination of these Terms for any reason, all rights
                  granted to you under these Terms will also terminate and you
                  must immediately cease all use of the Services. Termination
                  will not limit any of our rights or remedies at law or in
                  equity.
                </Typography>
                <Typography pt={3}>
                  The provisions set forth in Sections 2, 3, 4, 5, 8, 10, 11,
                  13, 14 and 15, and any other right or obligation under these
                  Terms that, by its nature, should survive termination of these
                  Terms, shall survive any termination of these Terms.
                </Typography>
                <Typography pt={3}>
                  You agree that any violation or threatened violation by you of
                  these Terms will cause us irreparable and unquantifiable harm.
                  You also agree that monetary damages would be inadequate for
                  such harm and consent to our obtaining any injunctive or
                  equitable relief that we deem necessary or appropriate,
                  without any requirement to post a bond or other security.
                  These remedies are in addition to any other remedies we may
                  have at law or in equity or as set forth in these Terms.
                </Typography>
                <Typography pt={3} variant="h6">
                  9. MODIFICATIONS AND INTERRUPTIONS{" "}
                </Typography>
                <Typography pt={3}>
                  We reserve the right to change, modify, or remove the contents
                  of the Services at any time or for any reason at our sole
                  discretion without notice. We have no obligation to update any
                  information on our Services. We will not be liable to you or
                  any third party for any modification, suspension, or
                  discontinuance of the Services.
                </Typography>
                <Typography pt={3}>
                  We cannot guarantee the Services will be available at all
                  times. We may experience hardware, software, or other problems
                  or need to perform maintenance related to the Services,
                  resulting in interruptions, delays, or errors. We reserve the
                  right to change, revise, update, suspend, discontinue, or
                  otherwise modify the Services at any time or for any reason
                  without notice to you. You agree that we have no liability
                  whatsoever for any loss, damage, or inconvenience caused by
                  your inability to access or use the Services during any
                  downtime or discontinuance of the Services. Nothing in these
                  Terms will be construed to obligate us to maintain and support
                  the Services or to supply any corrections, updates, or
                  releases in connection therewith.
                </Typography>
                <Typography pt={3} variant="h6">
                  10. GOVERNING LAW{" "}
                </Typography>
                <Typography pt={3}>
                  These Terms and your use of the Services are governed by and
                  construed in accordance with the laws of the State of Alabama
                  applicable to agreements made and to be entirely performed
                  within the State of Alabama, without regard to its conflict of
                  law principles.{" "}
                </Typography>
                <Typography pt={3} variant="h6">
                  11. DISPUTE RESOLUTION{" "}
                </Typography>
                <Typography pt={3}>
                  <b>Informal Negotiations </b>
                </Typography>
                <Typography pt={3}>
                  To expedite resolution and control the cost of any dispute,
                  controversy, or claim related to these Terms (each a "Dispute"
                  and collectively, the "Disputes") brought by either you or us
                  (individually, a "Party" and collectively, the "Parties"), the
                  Parties agree to first attempt to negotiate any Dispute
                  (except those Disputes expressly provided below) informally
                  for at least thirty (30) days before initiating litigation.
                  Such informal negotiations commence upon written notice from
                  one Party to the other Party. If the Parties are unable to
                  resolve a Dispute through informal negotiations, the Dispute
                  shall be commenced or prosecuted exclusively in the state and
                  federal courts located in Jefferson County, Alabama, and the
                  Parties hereby consent to, and waive all defenses of lack of
                  personal jurisdiction, and forum non conveniens with respect
                  to venue and jurisdiction in such state and federal courts. In
                  no event may you commence any Dispute related in any way to
                  the Services more than one (1) year after the cause of action
                  arose. Each Party irrevocably and unconditionally waives any
                  right it may have to a trial by jury with respect to any
                  Dispute. In the event that we are the prevailing party in any
                  Dispute, we are entitled to recover our actual attorneys’ fees
                  and court costs from you.
                </Typography>
                <Typography pt={3} variant="h6">
                  12. CORRECTIONS{" "}
                </Typography>
                <Typography pt={3}>
                  There may be information on the Services that contains
                  typographical errors, inaccuracies, or omissions. We reserve
                  the right to correct any errors, inaccuracies, or omissions
                  and to change or update the information on the Services at any
                  time, without prior notice.
                </Typography>
                <Typography pt={3} variant="h6">
                  13. DISCLAIMER
                </Typography>
                <Typography pt={3}>
                  THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS.
                  YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE
                  RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
                  WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE
                  SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION,
                  THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                  PARTICULAR PURPOSE, AND NONINFRINGEMENT. WE MAKE NO WARRANTIES
                  OR REPRESENTATIONS ABOUT THE ACCURACY
                </Typography>
                <Typography>OR</Typography>
                <Typography>
                  COMPLETENESS OF THE SERVICES' CONTENT AND WE WILL ASSUME NO
                  LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
                  INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR
                  PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
                  ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS
                  TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
                  INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4)
                  ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
                  SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
                  WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY
                  THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT
                  AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED
                  AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR
                  OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE MAKE NO
                  REPRESENTATIONS OR WARRANTIES THAT USE OF THE SERVICES OR THE
                  DATA PROVIDED THROUGH THE SERVICES WILL BE UNINTERRUPTED,
                  COMPLETELY SECURE, VIRUS-FREE OR ERROR-FREE, THAT DEFECTS WILL
                  BE CORRECTED, OR THAT THE SERVICES OR DATA OBTAINED THROUGH
                  THE SERVICES WILL ACHIEVE ANY INTENDED RESULTS OR OTHERWISE
                  MEET YOUR NEEDS OR EXPECTATIONS.
                </Typography>
                <Typography pt={3}>
                  The information presented on or through the Services is made
                  available solely for general information purposes. We do not
                  warrant the accuracy, completeness, or usefulness of this
                  information. Any reliance you place on such information is
                  strictly at your own risk. We disclaim all liability and
                  responsibility arising from any reliance placed on such
                  materials by you, or by anyone who may be informed of any of
                  its contents.
                </Typography>
                <Typography pt={3} variant="h6">
                  14. LIMITATIONS OF LIABILITY{" "}
                </Typography>
                <Typography pt={3}>
                  TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL WE OR
                  OUR DIRECTORS, OFFICERS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU
                  OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
                  EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, OR
                  DAMAGES FOR LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER
                  DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE
                  BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                  NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
                  LIABILITY TO YOU IN CONNECTION WITH THESE TERMS OR THE
                  SERVICES FOR ANY CAUSE WHATSOEVER, REGARDLESS OF THE FORM OF
                  THE ACTION AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
                  BREACH OF CONTRACT OR OTHERWISE, WILL AT ALL TIMES BE LIMITED
                  TO THE AMOUNT OF $500.00 USD.
                </Typography>
                <Typography pt={3} variant="h6">
                  15. INDEMNIFICATION
                </Typography>
                <Typography pt={3}>
                  You agree to defend, indemnify, and hold harmless us, our
                  affiliates, and all of our respective officers, directors,
                  agents, partners, employees, successors and assigns from and
                  against any loss, damage, liability, award, judgment,
                  settlement, fine, cost, expense, claim, action or demand,
                  including without limitation attorneys’ fees and expenses,
                  arising out of or relating to: (1) your use of or inability to
                  use the Services; (2) your breach of these Terms; (3) any
                  breach of your representations and warranties set forth in
                  these Terms; (4) your violation of the rights of a third
                  party, including but not limited to intellectual property
                  rights, privacy rights or any rights in connection with PHI;
                  (5) your negligence, fraud or willful misconduct; (6) your
                  violation of applicable law; and (7) Your Data.
                  Notwithstanding the foregoing, we reserve the right, at your
                  expense, to assume the exclusive defense and control of any
                  matter for which you are required to indemnify us at your
                  expense, and you agree to cooperate, at your expense, with our
                  defense of such claims. We will use reasonable efforts to
                  notify you of any such claim, action, or proceeding which is
                  subject to this indemnification upon becoming aware of it.
                </Typography>
                <Typography pt={3} variant="h6">
                  16. USER DATA{" "}
                </Typography>
                <Typography pt={3}>
                  We will maintain certain data that you transmit to the
                  Services for the purpose of managing the performance of the
                  Services, as well as data relating to your use of the
                  Services. Although we perform regular routine backups of data,
                  you are solely responsible for all data that you transmit or
                  that relates to any activity you have undertaken using the
                  Services. You agree that we shall have no liability to you for
                  any loss or corruption of any such data, and you hereby waive
                  any right of action against us arising from any such loss or
                  corruption of such data.{" "}
                </Typography>
                <Typography pt={3} variant="h6">
                  17. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES{" "}
                </Typography>
                <Typography pt={3}>
                  Using the Services, sending us emails, and completing online
                  forms constitute electronic communications. You consent to
                  receive electronic communications, and you agree that all
                  agreements, notices, disclosures, and other communications we
                  provide to you electronically, via email and on the Services,
                  satisfy any legal requirement that such communication be in
                  writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
                  CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC
                  DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS
                  INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby
                  waive any rights or requirements under any statutes,
                  regulations, rules, ordinances, or other laws in any
                  jurisdiction which require an original signature or delivery
                  or retention of non-electronic records, or to payments or the
                  granting of credits by any means other than electronic means.
                </Typography>
                <Typography pt={3} variant="h6">
                  18. MISCELLANEOUS
                </Typography>
                <Typography pt={3}>
                  These Terms, the Services Agreement and the BAA constitute the
                  entire agreement and understanding between you and us. Our
                  failure to exercise or enforce any right or provision of these
                  Terms shall not operate as a waiver of such right or
                  provision. These Terms operate to the fullest extent
                  permissible by law. We may assign any or all of our rights and
                  obligations to others at any time. You may not assign or
                  otherwise transfer these Terms or your rights under these
                  Terms without our prior written consent, and any assignment or
                  transfer in violation of this prohibition will be null and
                  void. We shall not be responsible or liable for any loss,
                  damage, delay, or failure to act caused by any cause beyond
                  our reasonable control. If any provision or part of a
                  provision of these Terms is determined to be unlawful, void,
                  or unenforceable, that provision or part of the provision is
                  deemed severable from these Terms and does not affect the
                  validity and enforceability of any remaining provisions. There
                  is no joint venture, partnership, employment or agency
                  relationship created between you and us as a result of these
                  Terms or use of the Services. You agree that these Terms will
                  not be construed against us by virtue of having drafted them.
                  You hereby waive any and all defenses you may have based on
                  the electronic form of these Terms and the lack of signing by
                  the parties hereto to execute these Terms. No amendment or
                  modification of these Terms is effective unless it is in
                  writing and signed by authorized representatives of each of
                  us.
                </Typography>
                <Typography pt={3} variant="h6">
                  19. EXPORT REGULATION
                </Typography>
                <Typography pt={3}>
                  The Services may be subject to US export control laws,
                  including the Export Control Reform Act and its associated
                  regulations. You shall not, directly or indirectly, export,
                  re-export, or release the Services to, or make the Services
                  accessible from, any jurisdiction or country to which export,
                  re-export, or release is prohibited by law, rule, or
                  regulation. You shall comply with all applicable federal laws,
                  regulations, and rules, and complete all required undertakings
                  (including obtaining any necessary export license or other
                  governmental approval), prior to exporting, re-exporting,
                  releasing, or otherwise making the Services available outside
                  the US.
                </Typography>
                <Typography pt={3} variant="h6">
                  20. CONTACT US{" "}
                </Typography>
                <Typography pt={3}>
                  In order to resolve a complaint regarding the Services or to
                  receive further information regarding use of the Services,
                  please contact us at:{" "}
                </Typography>
                <Box textAlign={"center"}>
                  <Typography pt={3}>
                    <b>ProxsysRx, Inc.</b>
                  </Typography>
                  <Typography>
                    <b>1500 Urban Center Drive </b>
                  </Typography>
                  <Typography>
                    <b>Suite 325 </b>
                  </Typography>
                  <Typography>
                    <b>Birmingham, AL 35242 </b>
                  </Typography>
                  <Typography>
                    <b>United States </b>
                  </Typography>
                  <Typography>
                    <b>Phone: 205-533-9119 </b>
                  </Typography>
                  <Typography>
                    <b>Email: 340bpro@proxsysrx.com </b>
                  </Typography>
                </Box>
              </div>
            </CardContent>
          </Grid>
          <Grid item xs={12} md={5} lg={4} xl={3}>
            <CardContent>
              <FormControl>
                <RadioGroup name="agree-box">
                  <FormControlLabel
                    control={<Checkbox disabled={!isAgreeEnabled} />}
                    label="I agree"
                    onClick={handleAgree}
                  />
                </RadioGroup>
              </FormControl>
              <Stack direction={"row"}>
                <Button disabled={!isContinueEnabled} onClick={handleContinue}>
                  Continue
                </Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </Stack>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Paper>
  );
};
