import React from "react";
import {
  Popover,
  Button,
  Paper,
  CardHeader,
  CardContent,
  Card,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import { AdminService } from "../../../services/adminService";
import Swal from "sweetalert2";

export default function AdminUnsubmitPopover({
  rx_prx_id,
  audit_type,
  allInfo,
  setAllInfo,
  setOpen,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [note, setNote] = React.useState("");
  const { adminUnsubmitRecord } = AdminService();

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });

  const handleClick = () => {
    setAnchorEl(document.body);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNote("");
  };

  const handleUnsubmit = () => {
    adminUnsubmitRecord(
      rx_prx_id,
      audit_type,
      note,
      () => {
        const filtered = allInfo.filter((a) => a.rx_prx_id != rx_prx_id);
        setOpen(false);
        setAllInfo(() => filtered);
        swalWithBootstrapButtons.fire(
          "Unsubmitted",
          `Record has been unsubmitted.`,
          "success"
        );
      },
      (err) => {
        swalWithBootstrapButtons.fire(
          "Error.",
          `There was an error processing your request. ${err}`,
          "error"
        );
      }
    );
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Stack justifyContent={"center"}>
      <Button
        sx={{ m: 2 }}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        Unsubmit Record
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        //anchorReference="anchorPosition"
        // anchorPosition={{
        //   top: window.innerHeight / 2,
        //   left: window.innerWidth / 2,
        // }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        sx={{ width: "auto" }}
      >
        <Paper sx={{ m: 3, backgroundColor: "#B9D7EA" }}>
          <Card>
            <CardHeader title="Unsubmit Record" />
            <CardContent>
              <Typography>Add a note:</Typography>
              <Stack justifyContent={"center"}>
                <TextField
                  required
                  id="note-field"
                  value={note}
                  variant="outlined"
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setNote(() => e.target.value);
                  }}
                />
                <Button
                  variant="contained"
                  sx={{ mt: 1 }}
                  onClick={handleUnsubmit}
                >
                  SUBMIT
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Paper>
      </Popover>
    </Stack>
  );
}
