import React from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { UserContext } from "../../contexts/userContext";
import { AdminService } from "../../services/adminService";
import Swal from "sweetalert2";

export default function AdminAddUser({ setAnchorEl }) {
  const [firstName, setFirstName] = React.useState();
  const [lastName, setLastName] = React.useState();
  const [email, setEmail] = React.useState();
  const [tempPassword, setTempPassword] = React.useState();
  const [roles, setRoles] = React.useState([]);
  const [permissions, setPermissions] = React.useState([]);
  const [selectedPermissions, setSelectedPermissions] = React.useState([]);
  const [selectRoles, setSelectRoles] = React.useState("");
  const [managers, setManagers] = React.useState([]);
  const [selectManagers, setSelectManagers] = React.useState("");
  const { getAllRoles, getAllUsers, submitNewUser } = AdminService();
  const userCTX = React.useContext(UserContext);
  const { user } = userCTX;

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });

  React.useEffect(() => {
    if (!user || user.role_id != 3) return;
    getAllRoles(
      (data) => {
        setRoles(data.filter((r) => r.role_id < 4));
        setPermissions(data.filter((r) => r.role_id >= 4));
      },
      (err) => {
        console.log(err);
      }
    );
    getAllUsers(
      (data) => {
        setManagers(data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  const handleChangeRole = (e) => {
    setSelectRoles(e.target.value);
  };
  const handleChangeManager = (e) => {
    setSelectManagers(e.target.value);
  };

  const handlePermissionChange = (event, permissionId) => {
    if (event.target.checked) {
      setSelectedPermissions([...selectedPermissions, permissionId]);
    } else {
      setSelectedPermissions(
        selectedPermissions.filter((id) => id !== permissionId)
      );
    }
  };
  const handleSubmit = () => {
    if (!firstName || !lastName || !email || !roles) {
      return alert(
        "Must fill out first name, last name, email, and role to submit."
      );
    }
    submitNewUser(
      firstName,
      lastName,
      email,
      tempPassword,
      selectRoles,
      selectManagers,
      selectedPermissions,
      (data) => {
        setAnchorEl(null);
        swalWithBootstrapButtons.fire(
          "Submitted!",
          `User has been added.`,
          "success"
        );
      },
      (err) => {
        setAnchorEl(null);
        swalWithBootstrapButtons.fire(
          "Error.",
          `There was an error processing your request. ${err}`,
          "error"
        );
      }
    );
  };
  if (!user || user.role_id != 3) return;
  return (
    <Paper variant="outlined" sx={{ minWidth: 400 }}>
      <Typography
        component={"span"}
        sx={{ display: "flex", flexDirection: "column", m: 2 }}
      >
        <FormControl sx={{ m: 1 }}>
          <InputLabel htmlFor="my-input">First Name</InputLabel>
          <Input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <InputLabel htmlFor="my-input">Last Name</InputLabel>
          <Input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <InputLabel htmlFor="my-input">Email Address</InputLabel>
          <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <InputLabel htmlFor="my-input">Temporary Password</InputLabel>
          <Input
            value={tempPassword}
            onChange={(e) => setTempPassword(e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectRoles}
              label="Role"
              onChange={handleChangeRole}
            >
              {roles.map((role, index) => (
                <MenuItem value={role.role_id} key={role.role_id}>
                  {`${role.name} User: (${role.description})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Manager</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectManagers}
            label="Manager"
            onChange={handleChangeManager}
          >
            {managers.map((man, index) => {
              return (
                <MenuItem value={man.user_id} key={man.user_id}>
                  {`${man.user_first_name} ${man.user_last_name}`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <Typography variant="h6">Page Permissions</Typography>
          {permissions.map((permission) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedPermissions.includes(permission.role_id)}
                  onChange={(event) =>
                    handlePermissionChange(event, permission.role_id)
                  }
                />
              }
              label={permission.name}
              key={permission.role_id}
            />
          ))}
        </FormControl>
      </Typography>
      <Button variant="contained" onClick={handleSubmit} sx={{ width: "100%" }}>
        Submit
      </Button>
    </Paper>
  );
}
