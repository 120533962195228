import "./App.css";
import Encounter from "./screens/Encounter";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Login from "./screens/Login";
import { UserContext } from "./contexts/userContext";
import React from "react";
import History from "./screens/History";
import ResponsiveAppBar from "./components/NavBar";
import AdminPage from "./screens/Admin";
import Settings from "./screens/Settings";
import SignOutScreen from "./screens/SignOutScreen";
import LandingPage from "./screens/LandingPage";
import Compliance from "./screens/Compliance";
import cookies from "js-cookie";
import { useRecoilState } from "recoil";
import { pageAtom } from "./atoms/atoms";
import { TermsAndConditions } from "./components/TermsAndConditions";
import Referral from "./screens/Referral";
import { getHomePath, getHomeRoute, getHomeURL } from "./utils/homeRoute";
import AdminAudit from "./screens/AdminAudit";

function App() {
  const userCtx = React.useContext(UserContext);
  const { user,isSessionAlive, logout } = userCtx;
  const [pageRef, setPageRef] = useRecoilState(pageAtom);
  const [selected, setSelected] = React.useState(false);
  const [cookie, setCookie] = React.useState();
  const [appBarHeight, setAppBarHeight] = React.useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  React.useEffect(() => {
    
    if(!isSessionAlive()){
      localStorage.removeItem("token");
      if(location.pathname !== "/Login"){
        navigate("/Login");
      }
    }
    
    if (user && user.userInfo) {
      const pathSegments = location.pathname.split("/");
      let active;

      switch (pathSegments[2]) {
        case "Audit":
          active = "Encounter Audit";
          break;
        case "Compliance":
          active = "Compliance Audit";
          break;
        case "Referral":
          active = "Referral Audit";
          break;
        case "":
          active = getHomePath(user.user_secondary_roles[0]);
          break;
        default:
          active = pathSegments[2];
          break;
      }

      if (pathSegments[2] === "" && pathSegments.length === 3) {
        // Get the correct home path based on the first secondary role
        const homePath = getHomeURL(user.user_secondary_roles[0]);
        // Use the navigate function to change the URL
        navigate(`/${pathSegments[1]}/${homePath}`);
      }

      if (active != pageRef.activePage) {
        setPageRef((prev) => ({
          ...prev,
          activePage: active,
        }));
      }
    }
  }, [location.pathname, user, navigate, pageRef]);

  React.useEffect(() => {
    if (cookie) return;
    const tenantCookie = cookies.get("tenant");
    setCookie(tenantCookie);
  }, [selected]);

  if (user) {
    
    if (!user.terms_date) {
      return <TermsAndConditions />;
    } else {
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              paddingTop: `${appBarHeight}px`, // Adjust top padding dynamically
            }}
          >
            <ResponsiveAppBar onAppBarHeightChange={setAppBarHeight} />

            <div
              style={{
                flex: 1,
                textAlign: "center",
                backgroundColor: "#769FCD",
              }}
            >
              <Routes>
                {user.user_secondary_roles[0] &&
                  getHomeRoute(user.user_secondary_roles[0])}
                <Route path="/Audit" element={<Encounter />} />
                <Route path="/History" element={<History />} />
                <Route path="/Admin" element={<AdminPage />} />
                <Route path="/AdminAudit" element={<AdminAudit />} />
                <Route path="/Settings" element={<Settings />} />
                <Route path="/LogOut" element={<SignOutScreen />} />
                <Route path="/Compliance" element={<Compliance />} />
                <Route path="/Referral" element={<Referral />} />
              </Routes>
            </div>
          </div>
        </LocalizationProvider>
      );
    }
  } else {
    if (!cookie) {
      if (!selected) {
        return <LandingPage setSelected={setSelected} />;
      }
    } else {
      return (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/LogOut" element={<SignOutScreen />} />
        </Routes>
      );
    }
  }
}

export default App;
