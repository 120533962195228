import { baseApi } from "./baseService";
import { LogOut } from "../utils/logOut";

export function AuditService() {
  const { loggedOut } = LogOut();
  const API = {
    getAllAuditedEncounters: "/audit/encounters",
    getAllAuditedReferrals: "/audit/referrals",
    getAuditDetail: "/audit/detail",
    getAllAuditedAudits: "/audit/audits",
    getAllAuditedCompliance: "/audit/compliance",
    getAllAuditedNMR: "/audit/nonmatchReferral",
    userOptOut: "/audit/optOut",
    exportRecords: "/audit/export",
    agreeToTems: "/audit/terms",
    getFormDetail: "/audit/formDetail",
    reaudit: "/audit/reaudit",
  };

  const getAllAuditedEncounters = (userIds, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAllAuditedEncounters, { userIds })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getAllAuditedReferrals = (userIds, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAllAuditedReferrals, { userIds })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  const getAllAuditedAudits = (userIds, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAllAuditedAudits, { userIds })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  const getAllAuditedCompliance = (userIds, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAllAuditedCompliance, { userIds })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  const getAuditDetail = (rx_prx_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAuditDetail, {
          rx_prx_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const userOptOut = (opt_out, type, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.userOptOut, {
          opt_out,
          type,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const exportRecords = (historyInfo, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(
          API.exportRecords,
          
            historyInfo,
          
          { responseType: "blob" }
        )
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  const agreeToTerms = (termsAgreed, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.agreeToTems, {
          termsAgreed,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  const getFormDetail = (audit_type, rx_prx_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getFormDetail, {
          rx_prx_id,
          audit_type,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  const reaudit = (rx_prx_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.reaudit, {
          rx_prx_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  return {
    getAllAuditedEncounters,
    getAllAuditedReferrals,
    getAuditDetail,
    getAllAuditedAudits,
    userOptOut,
    exportRecords,
    getAllAuditedCompliance,
    agreeToTerms,
    getFormDetail,
    reaudit,
  };
}
